import {
  faArrowRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { logout } from "../../../store/auth";
import { useMsgSuccess } from "../../../toastify/notification";
import Swal from "sweetalert2";
import { getSingleItemStaff } from "../../../store/staff";
import { setOpenFormEditStaff } from "../../../store/staff/form";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Cookie from "cookie-universal";

const UserInfo = () => {
  const cookie = Cookie();
  const user_id = cookie.get("user_id_lad");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const msgSuccess = useMsgSuccess;

  useEffect(() => {
    dispatch(getSingleItemStaff(user_id));
  }, [dispatch, user_id]);

  const { singleItemStsff } = useAppSelector((state) => state.staff);

  return (
    <div className=" w-36 bg-white text-icon absolute top-12 right-[-20px] z-20 shadow-md flex flex-col gap-2 rounded-md">
      <p className=" px-2 pt-2">مرحبا!</p>
      <NavLink
        to="profile"
        onClick={() => dispatch(setOpenFormEditStaff(singleItemStsff))}
        className=" flex gap-2 items-center py-1 px-2 hover:bg-dropdown-active cursor-pointer transition-all duration-100"
      >
        <FontAwesomeIcon icon={faUser} />
        <p>حسابي</p>
      </NavLink>
      <div
        onClick={() => {
          Swal.fire({
            title: "هل تريد الاستمرار؟",
            imageUrl: "/media/img/logout.svg",
            imageWidth: 100,
            showCancelButton: true,
            confirmButtonColor: "#4A5CB6",
            cancelButtonColor: "#D9003F",
            confirmButtonText: "نعم",
            cancelButtonText: "لا",
          }).then((result: { isConfirmed: any }) => {
            if (result.isConfirmed) {
              dispatch(logout()).then(() => {
                navigate("/", { replace: true });
                msgSuccess("تم تسجيل الخروج بنجاح");
              });
            }
          });
        }}
        className=" flex gap-2 items-center py-1 px-2 pb-2 border-t hover:bg-dropdown-active cursor-pointer transition-all duration-100"
      >
        <FontAwesomeIcon icon={faArrowRightFromBracket} />
        <p className=" whitespace-nowrap">تسجيل الخروج</p>
      </div>
    </div>
  );
};

export default UserInfo;
