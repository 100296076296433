import React, { ComponentType } from "react";
import { useLocation } from "react-router-dom";
import Cookie from "cookie-universal";

const useGaurd = <P extends object>(Component: ComponentType<P>) => {
  const WrappedComponent: React.FC<P> = (props: any) => {
    const location = useLocation();
    const cookie = Cookie();
    const isLogin = cookie.get("access_token") ? true : false;

    if (location.pathname === "/login" && isLogin) {
      window.location.pathname = "/";
    } else if (location.pathname === "/login" && !isLogin) {
      return <Component {...props} />;
    }

    if (!isLogin) {
      window.location.pathname = "/login";
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default useGaurd;
