import { createSlice } from "@reduxjs/toolkit";
import { COMPANIES } from "../../schema/tables/Administrative/companies";

interface administrativeForm {
  defaultForm: any;
  administrative: any;
  isShowFormEditAdministrative: { status: boolean; id: number | null };
  isShowFormAddAdministrative: boolean;
  field: string;
  filename: any;
  platform: {
    name: string;
    endPoint: string;
    columns: [];
  };
  filesDetails: {
    data: any[];
    filed: string;
    showModal: boolean;
  };
}

const initialState: administrativeForm = {
  defaultForm: {},
  administrative: {},
  isShowFormEditAdministrative: { status: false, id: null },
  isShowFormAddAdministrative: false,
  field: "",
  filename: "",
  platform: {
    name: "",
    endPoint: "",
    columns: [],
  },
  filesDetails: {
    data: [],
    filed: "",
    showModal: false,
  },
};

const administrativeFormSlice = createSlice({
  name: "administrativeSlice",
  initialState,
  reducers: {
    selectPlatform: (state, action) => {
      const { id }: { id: number } = action.payload;
      const company: any = COMPANIES.find((item) => item.id === id);
      state.platform.name = company.text;
      state.platform.endPoint = company.endPoint;
      state.platform.columns = company.schema;
      state.defaultForm = {};
      state.defaultForm = company.form;
      for (let key in company.form) {
        if (key.split("_")[1] === "file") {
          state.filename = key;
        }
      }
    },
    setOpenFormAddAdministrative: (state) => {
      state.isShowFormAddAdministrative = true;
      state.field = "addMode";
      state.administrative = state.defaultForm;
    },
    setCloseFormAddAdministrative: (state) => {
      state.isShowFormAddAdministrative = false;
      state.field = "";
      state.administrative = {};
    },
    setOpenFormEditAdministrative: (state: any, action) => {
      const { item }: { item: any } = action.payload;
      state.isShowFormEditAdministrative.status = true;
      state.isShowFormEditAdministrative.id = item.id;
      state.field = "editMode";
      state.administrative = {};
      state.administrative = item;
    },
    setCloseFormEditAdministrative: (state) => {
      state.isShowFormEditAdministrative.status = false;
      state.isShowFormEditAdministrative.id = null;
      state.field = "";
      state.administrative = {};
    },
    setFormAdministrative: (state, action) => {
      const { name, value } = action.payload;
      state.administrative[name] = value;
    },
    setDefaultPlatform: (state) => {
      state.platform.name = "";
      state.platform.endPoint = "";
      state.administrative = {};
      state.field = "";
    },
    setFilesDetails: (state, action) => {
      const { data, filed, showModal } = action.payload;
      state.filesDetails.data = data;
      state.filesDetails.filed = filed;
      state.filesDetails.showModal = showModal;
    },
    btnBackPage: (state) => {
      state.platform.name = "";
      state.platform.endPoint = "";
      state.administrative = {};
      state.field = "";
      state.platform.columns = [];
      state.isShowFormAddAdministrative = false;
      state.isShowFormEditAdministrative.status = false;
      state.isShowFormEditAdministrative.id = null;
    },
    btnDeleteFiles: (state) => {
      state.filesDetails.data = [];
    }
  },
  extraReducers: (builder) => {},
});

export default administrativeFormSlice.reducer;
export const {
  selectPlatform,
  setFormAdministrative,
  setDefaultPlatform,
  setOpenFormAddAdministrative,
  setCloseFormAddAdministrative,
  setOpenFormEditAdministrative,
  setCloseFormEditAdministrative,
  btnBackPage,
  setFilesDetails,
  btnDeleteFiles,
} = administrativeFormSlice.actions;
