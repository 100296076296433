import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleDown,
  faBars,
  faCaretLeft,
  faCompress,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { setReSizeScrean, setShowSidebarMobile, setShowUserInfo } from "../../../store/global";
import UserInfo from "../dropdown/UserInfo";
import Cookie from "cookie-universal";

const TopBar: React.FC = () => {
  const cookie = Cookie();
  const dispatch = useAppDispatch();
  const { showUserInfo, reSizeScrean } = useAppSelector((state) => state.global);
  const { platform } = useAppSelector((state) => state.administrativeForm);

  const itemsNav = [
    {
      id: 0,
      path: "/dashboard",
      text: "لوحة التحكم",
    },
    {
      id: 1,
      path: "/dashboard/staff",
      text: "الموظفين",
    },
    {
      id: 2,
      path: "/dashboard/administrative_affairs",
      text: "الشؤون الأدارية",
    },
    {
      id: 3,
      path: "/dashboard/permission",
      text: "الصلاحيات",
    },
    {
      id: 4,
      path: "/dashboard/support",
      text: "الدعم",
    },
    {
      id: 5,
      path: "/dashboard/profile",
      text: "حسابي",
    },
  ];

  const titlePage = itemsNav.find(
    (item) => item.path === window.location.pathname
  );

  return (
    <div className=" w-100 flex justify-between bg-body px-5 pt-3 pb-2">
      <div>
        <p className=" font-bold text-page-title-main">{titlePage?.text}</p>
        {
          titlePage?.path === "/dashboard/administrative_affairs" ? 
        <div className=" flex text-sm items-center text-muted font-bold gap-1 mr-3 -mb-2 select-none">
          <FontAwesomeIcon icon={faCaretLeft} />
          <p>{platform.name || '..............'}</p>
        </div>
        : ""
      }
      </div>
      <div className=" flex-1 flex gap-8 items-center justify-end">
        <div className=" hidden lg:flex text-xl text-muted cursor-pointer" onClick={() => dispatch(setReSizeScrean())}>
          {
            reSizeScrean ? <FontAwesomeIcon icon={faCompress} title="تصغيير الشاشة" /> : <FontAwesomeIcon icon={faExpand} title="تكبير الشاشة" />
          }
        </div>
        <div
          className={` flex gap-2 relative cursor-pointer ${
            showUserInfo ? "text-menu-item-active" : "text-icon"
          }`}
          onClick={() => dispatch(setShowUserInfo())}
        >
          <div className=" w-8 h-8 rounded-full overflow-hidden border">
            <img
              src="/media/img/avatar-staff.svg"
              alt=""
              className=" w-full h-full"
            />
          </div>
          <div className=" flex items-center gap-1">
            <p>{cookie.get("name_user") || "Unknown"}</p>
            {showUserInfo ? (
              <FontAwesomeIcon icon={faAngleDown} className=" w-3" />
            ) : (
              <FontAwesomeIcon icon={faAngleLeft} className=" w-3" />
            )}
          </div>
          {showUserInfo && <UserInfo />}
        </div>
        <div className=" lg:hidden">
          <FontAwesomeIcon
            icon={faBars}
            className=" text-lg cursor-pointer"
            onClick={() => dispatch(setShowSidebarMobile())}
          />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
