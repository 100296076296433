import React from "react";
import { setDropMenu } from "../../../store/global";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { setRoles } from "../../../store/staff/form";

interface props {
  text: string;
  data: any;
  name: any;
  fun: any;
}

const DropMenu: React.FC<props> = ({ text, data, name, fun }) => {
  const dispatch = useAppDispatch();
  const { dropMenu } = useAppSelector((state) => state.global);

  const item = data.map((item: any) => (
    <li
      className=" px-2 py-1 border-b hover:bg-[#ddd] cursor-pointer"
      key={item.name}
      onClick={() => {
        dispatch(setDropMenu());
        dispatch(setRoles(item.name));
      }}
    >
      {item.name}
    </li>
  ));

  return (
    <div className=" relative">
      <div
        className="my-auto bg-[#ddd] py-1 px-2 rounded-sm whitespace-nowrap cursor-pointer"
        onClick={() => dispatch(setDropMenu())}
      >
        <p>{text}</p>
      </div>
      {dropMenu && (
        <div className=" absolute right-0 bg-[#eee] w-full shadow-sm shadow-[#ddd]">
          <ul>{item}</ul>
        </div>
      )}
    </div>
  );
};

export default DropMenu;
