type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  placeholder?: string;
  cell?: ({ value }: { value: any }) => {};
};

type FORM = {
  commissioners_name: string | null;
  id_number: number | null;
  mandated_date: Date | null;
  mandated_expiry_date: Date | null;
  notes: string | null;
  senaei_file: File[] | null;
};

export const MCI: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "اسم المفوض",
    accessor: "commissioners_name",
    type: "text",
    placeholder: "اسم المفوض",
  },
  {
    Header: "رقم الهوية",
    accessor: "id_number",
    type: "text",
    placeholder: "رقم الهوية",
  },
  {
    Header: "تاريخ التفويض",
    accessor: "mandated_date",
    type: "date",
  },
  {
    Header: "تاريخ انتهاء التفويض",
    accessor: "mandated_expiry_date",
    type: "date",
  },
  {
    Header: "الملاحظات",
    accessor: "notes",
    type: "text",
    placeholder: "الملاحظات",
  },
  {
    Header: "المرفقات",
    accessor: "senaei_file",
    type: "file",
  },
];

export const FORM_MCI: FORM = {
  commissioners_name: null,
  id_number: null,
  mandated_date: null,
  mandated_expiry_date: null,
  notes: null,
  senaei_file: null,
};
