import { faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { setDropMenu } from "../../../store/global";

interface props {
  text: string;
  data: any;
  fun?: any;
}

const Select: React.FC<props> = ({ text, data, fun }) => {
  const dispatch = useAppDispatch();
  const { roles } = useAppSelector((state) => state.permissionForm);
  const { dropMenu } = useAppSelector((state) => state.global);

  return (
    <div>
      <div
        className="my-auto bg-muted shadow-box-2 py-1 px-2 rounded-md whitespace-nowrap cursor-pointer flex justify-between items-center w-44 mx-auto"
        onClick={() => dispatch(setDropMenu())}
      >
        <p>{text}{` (${roles.permission.length})`}</p>
        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} beat />
      </div>
      {dropMenu && (
        <div className=" fixed top-0 left-0 z-50 p-12 bg-[#ffffff80] blur-1 w-screen h-screen flex justify-center items-center">
            <div
              className=" absolute top-8 right-8 z-50 shadow-box-2 border border-black border-dashed bg-white w-9 h-9 cursor-pointer rounded-full flex justify-center items-center text-lg text-error"
              onClick={() => dispatch(setDropMenu())}
            >
              <FontAwesomeIcon icon={faXmark} beat className=" text-[#d9003f]" />
            </div>
          <div className=" relative w-full h-full rounded-md border border-dashed border-black bg-eee px-4 overflow-x-visible overflow-y-scroll">
            <div className=" text-center text-lg font-bold py-3 text-black w-full">
              <p className=" text-center mx-auto">أختر الصلاحيات {` (${roles.permission.length})`}</p>
            </div>
            <div className=" flex items-center flex-wrap gap-y-1 gap-x-4">
              
              {data.map((item: any, index: number) => (
                <label
                  className=" flex gap-x-2 cursor-pointer whitespace-nowrap text-sm"
                  key={index}
                >
                  <input
                    type="checkbox"
                    value={item}
                    checked={roles.permission.includes(item)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        fun({ name: 'permission' ,check: e.target.checked, value: item })
                      )
                    }
                  />
                  <p> {item} </p>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
