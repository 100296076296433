import React from "react";
import {
  faXmark,
  faPlus,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import DropMenu from "../dropdown/DropMenu";
import Select from "../dropdown/Select";
import FileUpload from "./FileUpload";
import { setFilesDetails } from "../../../store/administrative/form";
import { useAppSelector } from "../../../hooks/useAppSelector";

interface props {
  inputs: any[];
  closeForm: any;
  fun?: any;
  submit?: any;
  list?: any[];
  rules?: any[];
  values?: any;
}

const Form: React.FC<props> = ({
  inputs,
  closeForm,
  fun,
  list,
  submit,
  rules,
  values,
}) => {
  const dispatch = useAppDispatch();
  const { filesDetails, filename } = useAppSelector((state) => state.administrativeForm);

  const LIST = inputs.map((item: any, index: number) => {
    return (
      <>
        {item.accessor === "id" ? (
          <td className=" py-1 px-2 text-center" key={item.accessor}>
            #
          </td>
        ) : item.type === "dropmenu" ? (
          <td className="py-1 px-2" key={item.accessor}>
            <DropMenu
              text={"أختر الصلاحية"}
              data={list}
              fun={fun}
              name={item.accessor}
            />
          </td>
        ) : item.type === "select" ? (
          <td className="py-1 px-2" key={item.accessor}>
            <Select text="حدد الصلاحيات" data={rules} fun={fun} />
          </td>
        ) : item.type === "file" ? (
          <td className=" py-1 px-2 text-center" key={item.accessor}>
            <>
              <div
                className=" text-white cursor-pointer gap-1 bg-muted shadow-box-2 rounded-md py-1 px-3 whitespace-nowrap"
                onClick={() =>
                  dispatch(
                    setFilesDetails({
                      data: [],
                      filed: "add",
                      showModal: true,
                    })
                  )
                }
              >
                <p>مرفقات</p>
              </div>
              {filesDetails.showModal && <FileUpload name={item.accessor} />}
            </>
          </td>
        ) : (
          <td className=" py-1 px-2 text-center" key={item.accessor}>
            <input
              className=" border rounded-sm py-1 px-1 text-center text-sm outline-none focus:border-[#71b6f9]"
              type={item.type}
              placeholder={item.placeholder}
              value={"" || values[item.accessor]}
              onChange={(e) =>
                dispatch(fun({ name: item.accessor, value: e.target.value }))
              }
            />
          </td>
        )}
      </>
    );
  });

  return (
    <tr className="hover:bg-[#dee2e6] transition-all duration-100  border-b border-[#dee2e6]">
      {LIST}
      <td className=" py-3 px-2 flex justify-center items-center gap-1">
        <FontAwesomeIcon
          icon={faPlus}
          className=" cursor-pointer bg-[#24c732] py-1 px-2 rounded-sm text-white"
          onClick={() => submit()}
        />
        <FontAwesomeIcon
          icon={faXmark}
          className=" cursor-pointer bg-[#d9003f] py-1 px-2 rounded-sm text-white"
          onClick={() => dispatch(closeForm())}
        />
      </td>
    </tr>
  );
};

export default Form;
