import {
  faWhatsapp,
  faFacebook,
  faXTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const Support: React.FC = () => {
  return (
    <>
      <div className="bg-white h-vh-minus-96 rounded-md flex flex-col overflow-x-hidden overflow-y-scroll">
        <div className=" text-center max-w-4xl mx-auto mt-2 flex flex-col gap-2">
          <h1 className=" text-xl font-bold border-b border-dashed w-fit mx-auto pb-1">
            اتقـان تيك لتقنية المعلومات
          </h1>
          <p className=" text-muted">
            اتقان مؤسسة سعودية رائدة في مجال برمجة التطبيقات وتطوير المواقع
            الإلكترونية. منذ تأسيس اتقان، ركزنا على تقديم حلاً شاملاً ومبتكرًا
            لاحتياجات الأعمال والمشاريع التقنية.
          </p>
        </div>
        <div className=" mt-10 flex-1 px-4">
          <div className=" flex justify-center">
            <p className=" font-bold text-lg text-menu-item">تواصل معنا</p>
          </div>
          <div className=" flex flex-col lg:flex-row gap-4 py-4">
            <a
              href="https://wa.me/966549043906?text="
              target="_blank"
              className=" flex-1 flex flex-col items-center gap-2 text-lg text-muted border p-2 rounded-md hover:bg-eee hover:text-whatsapp cursor-pointer transition-all duration-150"
              rel="noreferrer"
            >
              <p className=" font-bold">whatsapp</p>
              <FontAwesomeIcon icon={faWhatsapp} className=" text-2xl" />
            </a>
            <a
              href="https://www.facebook.com/ITQANWEB?mibextid=ZbWKwL"
              target="_blank"
              className=" flex-1 flex flex-col items-center gap-2 text-lg text-muted border p-2 rounded-md hover:bg-eee hover:text-facebook cursor-pointer transition-all duration-150"
              rel="noreferrer"
            >
              <p className=" font-bold">facebook</p>
              <FontAwesomeIcon icon={faFacebook} className=" text-2xl" />
            </a>
            <a
              href="https://twitter.com/itqanweb"
              target="_blank"
              className=" flex-1 flex flex-col items-center gap-2 text-lg text-muted border p-2 rounded-md hover:bg-eee hover:text-twitter cursor-pointer transition-all duration-150"
              rel="noreferrer"
            >
              <p className=" font-bold">twitter</p>
              <FontAwesomeIcon icon={faXTwitter} className=" text-2xl" />
            </a>
            <a
              href="https://instagram.com/itqanweb"
              target="_blank"
              className=" flex-1 flex flex-col items-center gap-2 text-lg text-muted border p-2 rounded-md hover:bg-eee hover:text-instagram cursor-pointer transition-all duration-150"
              rel="noreferrer"
            >
              <p className=" font-bold">instagram</p>
              <FontAwesomeIcon icon={faInstagram} className=" text-2xl" />
            </a>
          </div>
          <div className=" w-32 mx-auto mt-5 mb-5 opacity-50">
            <img src="/media/img/itqan-logo.png" alt="" />
          </div>
        </div>
        <div className=" flex justify-center items-center gap-1 bg-eee py-4 text-sm">
          <p>جميع الحقوق محفوظة</p>
          <p> ل اتقـان تيك </p>
          <FontAwesomeIcon icon={faCopyright} />
          <p>2023</p>
        </div>
      </div>
    </>
  );
};

export default Support;
