
type COLUMNS = {
  Header: string;
  accessor?: string;
  type?: string;
  placeholder?: string;
  Cell?: ({ row }: { row: any }) => {};
};

export const PERMISSION: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "أسم الصلاحية",
    accessor: "name",
    type: "text",
    placeholder: "أسم الصلاحية",
  },
  {
    Header: "التحكم",
    accessor: "permissions",
    type: "select",
    Cell: ({ row }) => {
      const numberPerm = row.original.permissions.length;
      return `عدد الصلاحيات (${numberPerm})`;
    }
  }
];
