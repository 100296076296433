import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./global/index";
import staffSlice from "./staff/index";
import staffFormSlice from "./staff/form";
import authSlice from "./auth/index";
import authFormSlice from "./auth/form";
import permissionSlice from "./permission/index";
import permissionFormSlice from "./permission/form";
import administrativeSlice from "./administrative/index";
import administrativeFormSlice from "./administrative/form";

const store = configureStore({
  reducer: {
    global: globalSlice,
    staff: staffSlice,
    staffForm: staffFormSlice,
    auth: authSlice,
    authForm: authFormSlice,
    permission: permissionSlice,
    permissionForm: permissionFormSlice,
    administrative: administrativeSlice,
    administrativeForm: administrativeFormSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
