import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";

const Pagination: React.FC<any> = ({
  columns,
  data,
  nextPage,
  canNextPage,
  canPreviousPage,
  previousPage,
  pageOptions,
  gotoPage,
  pageCount,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  return (
    <div className="bg-white h-12 rounded-br-md rounded-bl-md border-t-2 border-body flex flex-row-reverse justify-between items-center p-3">
      <div className=" text-sm font-bold flex items-center text-menu-item border rounded-md">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className={` bg-input-search py-1 px-2 hover:bg-[#dee2e6] ${
            !canPreviousPage ? "cursor-no-drop opacity-70" : "cursor-pointer"
          } `}
        >
          1
        </button>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={` bg-input-search py-1 px-2 hover:bg-[#dee2e6] ${
            !canNextPage ? "cursor-no-drop opacity-70" : "cursor-pointer"
          } `}
        >
          <FontAwesomeIcon icon={faForward} />
        </button>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className={` bg-input-search py-1 px-2 hover:bg-[#dee2e6] ${
            !canPreviousPage ? "cursor-no-drop opacity-70" : "cursor-pointer"
          } `}
        >
          <FontAwesomeIcon icon={faBackward} />
        </button>
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className={` bg-input-search py-1 px-2 hover:bg-[#dee2e6] ${
            !canNextPage ? "cursor-no-drop opacity-70" : "cursor-pointer"
          } `}
        >
          {pageOptions.length}
        </button>
      </div>
      <div className=" text-sm flex items-center gap-1 font-bold text-menu-item">
        <span>الصفحة</span>
        <span>{pageIndex + 1}</span>
        <span>من</span>
        <span>{pageOptions.length}</span>
      </div>
      <div className=" hidden md:flex items-center gap-2 text-menu-item text-sm">
        <select
          value={pageSize}
          className=" border rounded-md h-8 bg-input-search outline-none cursor-pointer"
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              show {pageSize}
            </option>
          ))}
        </select>
        <input
          type="number"
          placeholder={`الصفحة : ${pageIndex + 1} `}
          className=" border rounded-md px-2 h-8 w-28 text-sm outline-none bg-input-search"
          min="1"
          max={pageOptions.length}
          onChange={(e) => {
            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNumber);
          }}
        />
      </div>
    </div>
  );
};

export default Pagination;
