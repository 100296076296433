import React, { useEffect } from "react";
import { useMsgInfo } from "../../toastify/notification";
import useDeleteItem from "../../toastify/deleteItem";
import { useValidate } from "../../hooks/useValidate";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";

import { STAFF } from "../../schema/tables/staff/staff";
import PlanCompanies from "../../components/dashboard/layouts/PlanCompnies";
import Form from "../../components/dashboard/forms/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { getAllItemsPermission } from "../../store/permission";
import {
  getAllItemsStaff,
  createItemStaff,
  deleteItemStaff,
  updateItemStaff,
} from "../../store/staff";
import {
  setCloseFormAddStaff,
  setCloseFormEditStaff,
  setForm,
  setOpenFormAddStaff,
  setOpenFormEditStaff,
} from "../../store/staff/form";

const Staff: React.FC = () => {
  const messageInfo = useMsgInfo;
  const deleteItem = useDeleteItem;
  const dispatch = useAppDispatch();

  const { rulesData } = useAppSelector((state) => state.permission);
  const { staffData, isLoading } = useAppSelector((state) => state.staff);
  const { staff, isShowFormAddStaff, isShowFormEditStaff, field } =
    useAppSelector((state) => state.staffForm);

  const handlerDelete = (id: number) => {
    dispatch(deleteItemStaff(id));
  };

  useEffect(() => {
    dispatch(getAllItemsStaff(1));
    dispatch(getAllItemsPermission(1));
  }, [dispatch]);

  const ColumnsSchema = [
    ...STAFF,
    {
      Header: "إجراءات",
      Cell: ({ row }: { row: any }) => (
        <div className=" text-center whitespace-nowrap flex justify-center items-center gap-3">
          <FontAwesomeIcon
            icon={faPen}
            className="text-[#24c732] text-lg cursor-pointer"
            onClick={() => dispatch(setOpenFormEditStaff(row.original))}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            className="text-[#d9003f] text-lg cursor-pointer"
            onClick={() => deleteItem(() => handlerDelete(row.original.id))}
          />
        </div>
      ),
    },
  ];

  const Validate = useValidate(staff, STAFF);

  const submit = (indexItem?: number) => {
    if (Validate) {
      messageInfo(`أدخل ${Validate}`);
    } else {
      if (field === "addMode") {
        dispatch(createItemStaff(staff)).then((res) => {
          if (res.payload.successful) {
            dispatch(setCloseFormAddStaff());
          }
        });
      } else if (field === "editMode") {
        dispatch(updateItemStaff({ form: staff, indexItem })).then(
          (res: any) => {
            if (res.payload.data.successful) {
              dispatch(setCloseFormEditStaff());
            }
          }
        );
      }
    }
  };

  return (
    <PlanCompanies
      columns={ColumnsSchema}
      data={staffData}
      ShowFormAdd={isShowFormAddStaff}
      openFormAdd={setOpenFormAddStaff}
      loading={isLoading}
      btnBackPage={false}
      inputs={STAFF}
      showFormEdit={isShowFormEditStaff}
      closeFormEdit={setCloseFormEditStaff}
      funEdit={setForm}
      itemData={staff}
      list={rulesData}
      submit={submit}
    >
      <Form
        inputs={STAFF}
        closeForm={setCloseFormAddStaff}
        fun={setForm}
        submit={submit}
        list={rulesData}
        values={staff}
      />
    </PlanCompanies>
  );
};

export default Staff;
