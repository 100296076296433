import React from "react";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

interface props {
  text: string;
  type: string;
  placeholder: string;
  isError: boolean;
  alert: string;
  fun: any;
}

const Default: React.FC<props> = ({
  text,
  type,
  placeholder,
  isError,
  alert,
  fun,
}) => {
  const dispatch = useAppDispatch();

  return (
    <label className=" flex flex-col gap-1">
      <div className=" flex flex-col gap-2">
        <p className=" texxt-sm text-menu-item font-bold">{text}</p>
        <input
          type={type}
          placeholder={placeholder}
          className={` border-2 rounded-md outline-none w-full p-2 transition-all duration-150 ${
            isError ? " border-error" : "focus:border-active-input-auth"
          }`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(fun(e.target.value))
          }
        />
      </div>
      {isError && (
        <div className=" flex items-center gap-1 text-[12px] text-[#d9003f]">
          <FontAwesomeIcon icon={faCircleInfo} />
          <p>{alert}</p>
        </div>
      )}
    </label>
  );
};

export default Default;
