import { toast } from "react-toastify";

export const useMsgSuccess = (msg: string) => {
  toast.success(`${msg}`, {
    position: "top-right",
    autoClose: 800,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: { direction: "rtl" },
  });
};

export const useMsgError = (msg: string) => {
  toast.error(`${msg}`, {
    position: "top-right",
    autoClose: 800,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: { direction: "rtl" },
  });
};

export const useMsgWarning = (msg: string) => {
  toast.warning(`${msg}`, {
    position: "top-right",
    autoClose: 800,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: { direction: "rtl" },
  });
};

export const useMsgInfo = (msg: string) => {
  toast.info(`${msg}`, {
    position: "top-right",
    autoClose: 800,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: { direction: "rtl" },
  });
};


export const useMsgSwal = (msg: string, fun?: any) => {
}