import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "cookie-universal";
import { useMsgError, useMsgSuccess } from "../../toastify/notification";

const cookie = Cookie();

// [1] => Login.
export const login = createAsyncThunk(
  "authSlice/login",
  async (dataUser: { email: string; password: string }, thunkAPI) => {
    try {
      const res = await axios
        .post(`http://api.ladunadminservices.com/api/auth/login`, dataUser)
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Logout.
export const logout = createAsyncThunk(
  "authSlice/logout",
  async (_, thunkAPI) => {
    try {
      await axios.post(`http://api.ladunadminservices.com/api/auth/logout`, {
        token: cookie.get("access_token"),
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface auth {
  isLoading: boolean;
  OTPCode: string;
}

const initialState: auth = {
  isLoading: false,
  OTPCode: "123456",
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] => Login.
    builder.addCase(login.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action): void => {
      state.isLoading = false;
      console.log(action.payload);
      if (action.payload.status) {
        cookie.set("access_token", action.payload.access_token);
        cookie.set("permission", action.payload.roles[0].name);
        cookie.set("name_user", action.payload.name_user);
        useMsgSuccess(action.payload.message);
      } else {
        useMsgError(action.payload.message);
      }
    });
    builder.addCase(login.rejected, (state, action): void => {});

    // [2] => Logout.
    builder.addCase(logout.pending, (state, action): void => {});
    builder.addCase(logout.fulfilled, (state, action): void => {
      cookie.remove("access_token");
      cookie.remove("permission");
      cookie.remove("name_user");
    });
    builder.addCase(logout.rejected, (state, action): void => {});
  },
});

export default authSlice.reducer;
export const {} = authSlice.actions;
