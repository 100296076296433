import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  useMsgSuccess,
  useMsgInfo,
  useMsgError,
} from "../../toastify/notification";
import axios from "axios";
import Cookie from "cookie-universal";

const cookie = Cookie();

// [1] => Get All Items --> Administrative Company.
export const getAllItemPlatform = createAsyncThunk(
  "administrativeSlice/getAll",
  async (endPoint: string, thunkAPI) => {
    try {
      const res = await axios
        .get(`http://api.ladunadminservices.com/api/dashboard/${endPoint}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Create Item --> Administrative Company.
export const createItemPlatform = createAsyncThunk(
  "administrativeSlice/create",
  async (userData: any, thunkAPI) => {
    const formData = new FormData();
    for (let key in userData.form) {
      if (key.split("_")[1] === "file") {
        for (var i = 0; i < userData.form[key].length; i++) {
          formData.append(`${key}[]`, userData.form[key][i]);
        }
      } else {
        formData.append(key, userData.form[key]);
      }
    }

    try {
      const res = await axios
        .post(
          `http://api.ladunadminservices.com/api/dashboard/${userData.endPoint}`,
          formData,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Update Item --> Administrative Company.
export const updateItemPlatform = createAsyncThunk(
  "administrativeSlice/update",
  async (
    itemData: { endPoint: string; form: any; indexItem: any },
    thunkAPI
  ) => {
    console.log(itemData)
    const formData = new FormData();
    for (let key in itemData.form) {
      if (key.split("_")[1] === "file") {
        for (var i = 0; i < itemData.form[key].length; i++) {
          formData.append(`${key}[]`, itemData.form[key][i]);
        }
      } else {
        formData.append(key, itemData.form[key]);
      }
    }

    try {
      const res = await axios
        .post(
          `http://api.ladunadminservices.com/api/dashboard/${itemData.endPoint}/${itemData.form.id}`,
          formData,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return { data, indexItem: itemData.indexItem };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [4] => Delete Item --> Administrative Company.
export const deleteItemPlatform = createAsyncThunk(
  "administrativeSlice/delete",
  async (itemData: { id: number; endPoint: string }, thunkAPI) => {
    try {
      await axios.delete(
        `http://api.ladunadminservices.com/api/dashboard/${itemData.endPoint}/${itemData.id}`,
        {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        }
      );

      return itemData.id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [5] => Delete Item --> Administrative Company.
export const deleteFilesPlatform = createAsyncThunk(
  "administrativeSlice/deleteFiles",
  async (
    itemData: { id: number; endPoint: string; filename: any },
    thunkAPI
  ) => {
    try {
      const res = await axios
        .get(
          `http://api.ladunadminservices.com/api/dashboard/${itemData.endPoint}/delet-images/${itemData.id}`,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return {data, id: itemData.id, filename: itemData.filename };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface administrative {
  isLoading: boolean;
  platformData: any[];
}

const initialState: administrative = {
  isLoading: false,
  platformData: [],
};

const administrativeSlice = createSlice({
  name: "administrativeSlice",
  initialState,
  reducers: {
    removeData: (state) => {
      state.platformData = [];
    },
  },
  extraReducers: (builder) => {
    // [1] => Get All Items.
    builder.addCase(getAllItemPlatform.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(getAllItemPlatform.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.platformData = action.payload.data;
      } else {
        useMsgInfo("يرجى أعادة المحاولة");
      }
    });
    builder.addCase(getAllItemPlatform.rejected, (state, action) => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في الخادم");
    });

    // [2] => Create Item.
    builder.addCase(createItemPlatform.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(createItemPlatform.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.platformData.push(action.payload.data);
        useMsgSuccess("تم الإضافة بنجاح");
      } else {
        if (action.payload.message.id_number) {
          useMsgError("رقم الهوية مستخدم من قبل");
        } else if (action.payload.message.mandated_expiry_date) {
          useMsgError(
            "تاريخ انتهاء التفويض يجب أن يكون بعد تاريخ بداية التفويض."
          );
        } else {
          useMsgError("يرجى التأكد من البيانات");
        }
      }
    });
    builder.addCase(createItemPlatform.rejected, (state, action) => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في السيرفر");
    });

    // [3] => Update Item .
    builder.addCase(updateItemPlatform.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(updateItemPlatform.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log(action.payload)
      if (action.payload.data.successful) {
        state.platformData[action.payload.indexItem] = action.payload.data.data;
        useMsgSuccess("تم التعديل بنجاح");
      } else {
        if (action.payload.data.message.id_number) {
          useMsgError("رقم الهوية مستخدم من قبل");
        } else if (action.payload.data.message.mandated_expiry_date) {
          useMsgError(
            "تاريخ انتهاء التفويض يجب أن يكون بعد تاريخ بداية التفويض."
          );
        } else {
          useMsgError("يرجى التأكد من البيانات");
        }
      }
    });
    builder.addCase(updateItemPlatform.rejected, (state, action): void => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في الخادم");
    });

    // [4] => Delete Item.
    builder.addCase(deleteItemPlatform.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(deleteItemPlatform.fulfilled, (state, action): void => {
      state.isLoading = false;
      state.platformData = state.platformData.filter(
        (item) => item.id !== action.payload
      );
      useMsgSuccess("تم الحذف بنجاح");
    });
    builder.addCase(deleteItemPlatform.rejected, (state, action): void => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في السيرفر");
    });

    // [5] => Delete files Item.
    builder.addCase(deleteFilesPlatform.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(deleteFilesPlatform.fulfilled, (state, action): void => {
      state.isLoading = false;
      const { id, filename } = action.payload;
      state.platformData = state.platformData.map((item) =>
      item.id === id ? { ...item, [filename]: [] } : item
    );
      useMsgSuccess("تم الحذف الملفات بنجاح");
    });
    builder.addCase(deleteFilesPlatform.rejected, (state, action): void => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في السيرفر");
    });
  },
});

export default administrativeSlice.reducer;
export const { removeData } = administrativeSlice.actions;
