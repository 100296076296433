type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  placeholder?: string;
  Cell?: ({ row }: { row: any }) => {};
};

type FORM = {
  commissioners_name: string | null;
  id_number: number | null;
  mandated_date: Date | null;
  mandated_expiry_date: Date | null;
  notes: string | null;
  car_type: string | null;
  car_model: string | null;
  insurance_expiry_date: Date | null;
  form_expiry_date: Date | null;
  authorized_person_name: string | null;
  tamm_file: File[] | null;
};

export const TAMM: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
    // Cell: ({ row }) => {
    //   const numRow = Number(row.id) + 1;
    //   return numRow;
    // },
  },
  {
    Header: "اسم المفوض",
    accessor: "commissioners_name",
    type: "text",
    placeholder: "اسم المفوض",
  },
  {
    Header: "رقم الهوية",
    accessor: "id_number",
    type: "text",
    placeholder: "رقم الهوية",
  },
  {
    Header: "تاريخ التفويض",
    accessor: "mandated_date",
    type: "date",
  },
  {
    Header: "تاريخ انتهاء التفويض",
    accessor: "mandated_expiry_date",
    type: "date",
  },
  {
    Header: "الملاحظات",
    accessor: "notes",
    type: "text",
    placeholder: "الملاحظات",
  },
  {
    Header: "نوع السيارة ",
    accessor: "car_type",
    type: "text",
    placeholder: "نوع السيارة ",
  },
  {
    Header: "الموديل",
    accessor: "car_model",
    type: "text",
    placeholder: "الموديل",
  },
  {
    Header: "تاريخ انتهاء الاستمارة",
    accessor: "insurance_expiry_date",
    type: "date",
  },
  {
    Header: "تاريخ انتهاء التامين",
    accessor: "form_expiry_date",
    type: "date",
  },
  {
    Header: "معلومات المفوض على قيادة السيارة",
    accessor: "authorized_person_name",
    type: "text",
    placeholder: "معلومات المفوض على قيادة السيارة",
  },
  {
    Header: "المرفقات",
    accessor: "tamm_file",
    type: "file",
  },
];

export const FORM_TAMM: FORM = {
  commissioners_name: null,
  id_number: null,
  mandated_date: null,
  mandated_expiry_date: null,
  notes: null,
  car_type: null,
  car_model: null,
  insurance_expiry_date: null,
  form_expiry_date: null,
  authorized_person_name: null,
  tamm_file: null,
};
