import React from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

interface props {
  icon?: JSX.Element;
  text: string;
  customStyle: string;
  fun: any;
}

const Default: React.FC<props> = ({ icon, text, customStyle, fun }) => {
  const dispatch = useAppDispatch();

  return (
    <button
      className={`flex items-center gap-1 text-white cursor-pointer ${customStyle}`}
      onClick={() => {
        fun.map((item: any) => dispatch(item()));
      }}
    >
      {icon}
      <p>{text}</p>
    </button>
  );
};

export default Default;
