type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  placeholder?: string;
  cell?: ({ value }: { value: any }) => {};
};

export const STAFF: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "الأسم الأول",
    accessor: "first_name",
    type: "text",
    placeholder: "الأسم الأول",
  },
  {
    Header: "الأسم الثاني",
    accessor: "last_name",
    type: "text",
    placeholder: "الأسم الثاني",
  },
  {
    Header: "الصلاحية",
    accessor: "roles_name",
    type: "dropmenu",
  },
  {
    Header: "الجنسية",
    accessor: "nationality",
    type: "text",
    placeholder: "الجنسية",
  },
  {
    Header: "الإيميل",
    accessor: "email",
    type: "email",
    placeholder: "الإيميل",
  },
  {
    Header: "الجوال",
    accessor: "phone_number",
    type: "text",
    placeholder: "جوال",
  }
];
