import React, { useEffect } from "react";
import { useMsgInfo } from "../../toastify/notification";
import useDeleteItem from "../../toastify/deleteItem";
import { useValidate } from "../../hooks/useValidate";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";

import { COMPANIES } from "../../schema/tables/Administrative/companies";
import PlanCompnies from "../../components/dashboard/layouts/PlanCompnies";
import Form from "../../components/dashboard/forms/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import {
  deleteItemPlatform,
  createItemPlatform,
  getAllItemPlatform,
  updateItemPlatform,
  removeData,
} from "../../store/administrative";
import {
  setCloseFormEditAdministrative,
  setFormAdministrative,
  setOpenFormEditAdministrative,
  setOpenFormAddAdministrative,
  setCloseFormAddAdministrative,
  selectPlatform,
  btnBackPage,
} from "../../store/administrative/form";

const Administrative: React.FC = () => {
  const messageInfo = useMsgInfo;
  const deleteItem = useDeleteItem;
  const dispatch = useAppDispatch();

  const { isLoading, platformData } = useAppSelector(
    (state) => state.administrative
  );
  const {
    administrative,
    isShowFormAddAdministrative,
    isShowFormEditAdministrative,
    field,
    platform,
  } = useAppSelector((state) => state.administrativeForm);

  const handlerDelete = (id: number, endPoint: string) => {
    dispatch(deleteItemPlatform({ id, endPoint }));
  };

  const ColumnsSchema = [
    ...platform.columns,
    {
      Header: "إجراءات",
      Cell: ({ row }: { row: any }) => (
        <div className=" text-center whitespace-nowrap flex justify-center items-center gap-3">
          <FontAwesomeIcon
            icon={faPen}
            className="text-[#24c732] text-lg cursor-pointer"
            onClick={() => {
              dispatch(setOpenFormEditAdministrative({item:row.original}));
            }}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            className="text-[#d9003f] text-lg cursor-pointer"
            onClick={() =>
              deleteItem(() =>
                handlerDelete(row.original.id, platform.endPoint)
              )
            }
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (platform.endPoint.length > 0) {
      dispatch(getAllItemPlatform(platform.endPoint));
    }
  }, [dispatch, platform.endPoint]);


  const Validate = useValidate(administrative,platform.columns);

  const submit = (indexItem?: number) => {
    if (Validate) {
      messageInfo(`أدخل ${Validate}`);
    } else {
      if (field === "addMode") {
        dispatch(
          createItemPlatform({
            form: administrative,
            endPoint: platform.endPoint,
          })
        ).then((res) => {
          if (res.payload.successful) {
            dispatch(setCloseFormAddAdministrative());
          }
        });
      } else if (field === "editMode") {
        dispatch(
          updateItemPlatform({
            form: administrative,
            endPoint: platform.endPoint,
            indexItem,
          })
        ).then((res: any) => {
          if (res.payload.data.successful) {
            dispatch(setCloseFormEditAdministrative());
          }
        });
      }
    }
  };

  const company = COMPANIES.map((item) => (
    <div
      key={item.id}
      onClick={() => {
        dispatch(
          selectPlatform({
            id: item.id,
          })
        );
      }}
      className=" col-span-6 md:col-span-4 lg:col-span-3 flex flex-col gap-1 justify-center items-center bg-white hover:bg-eee transition-all duration-150 cursor-pointer rounded-md p-1 text-center shadow-box-2"
    >
      <div className=" w-32 h-12 rounded-md overflow-hidden">
        <img
          className=" w-full"
          src={`/media/img/platforms/${item.img}`}
          alt=""
        />
      </div>
      <p>{item.text}</p>
    </div>
  ));

  return (
    <>
      {platform.columns.length === 0 ? (
        <div className=" rounded-md h-vh-minus-96 overflow-y-scroll overflow-x-hidden p-2 grid grid-cols-12 gap-2">
          {company}
        </div>
      ) : (
        <PlanCompnies
          columns={ColumnsSchema}
          data={platformData}
          inputs={platform.columns}
          loading={isLoading}
          openFormAdd={setOpenFormAddAdministrative}
          ShowFormAdd={isShowFormAddAdministrative}
          showFormEdit={isShowFormEditAdministrative}
          closeFormEdit={setCloseFormEditAdministrative}
          itemData={administrative}
          funEdit={setFormAdministrative}
          btnBackPage={[btnBackPage,removeData]}
          submit={submit}
        >
          <Form
            inputs={platform.columns}
            fun={setFormAdministrative}
            closeForm={setCloseFormAddAdministrative}
            values={administrative}
            submit={submit}
          />
        </PlanCompnies>
      )}
    </>
  );
};

export default React.memo(Administrative);
