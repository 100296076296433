import { DALADY, FORM_DALADY } from "./balady";
import { COM, FORM_COM } from "./commerce";
import { CI, FORM_CI } from "./commerceAndInvestment";
import { CA, FORM_CA } from "./contractorsAuthority";
import { EJAR, FORM_EJAR } from "./ejar";
import { ELECTRICITY, FORM_ELECTRICITY } from "./electricity";
import { ETIMAD, FORM_ETIMAD } from "./etimad";
import { MCI, FORM_MCI } from "./industrial";
import { IPA, FORM_IPA } from "./industrialPropertyAuthority";
import { MUDAD, FORM_MUDAD } from "./mudad";
import { MUQEEM, FORM_MUQEEM } from "./muqeem";
import { NAJIZ, FORM_NAJIZ } from "./najiz";
import { WATER, FORM_WATER } from "./nationalWater";
import { PARKING, FORM_PARKING } from "./parking";
import { QIWA, FORM_QIWA } from "./qiwa";
import { COMAMBER, FORM_COMAMBER } from "./riyadhChamber";
import { SALAMAH, FORM_SALAMAH } from "./salamah";
import { INSURANCE, FORM_INSURANCE } from "./socialInsurance";
import { TAMM, FORM_TAMM } from "./tamm";
import { ZAKAT, FORM_ZAKAT } from "./zakatAndTax";

type companyData = {
  id: number;
  text: string;
  img: string;
  schema: any[];
  endPoint: string;
  form: {};
};

export const COMPANIES: companyData[] = [
  {
    id: 0,
    text: "منصة مدد",
    img: "mudad.png",
    endPoint: "madds",
    schema: MUDAD,
    form: FORM_MUDAD,
  },
  {
    id: 1,
    text: "منصة ايجار وعقود الايجار",
    img: "ejar.png",
    endPoint: "ejars",
    schema: EJAR,
    form: FORM_EJAR,
  },
  {
    id: 2,
    text: "شهادة التأمينات الاجتماعية",
    img: "gosi.png",
    endPoint: "sociaSecurity",
    schema: INSURANCE,
    form: FORM_INSURANCE,
  },
  {
    id: 3,
    text: "منصة تم",
    img: "tamm.png",
    endPoint: "tamm",
    schema: TAMM,
    form: FORM_TAMM,
  },
  {
    id: 4,
    text: "منصة قوى",
    img: "qiwa.png",
    endPoint: "qiwa",
    schema: QIWA,
    form: FORM_QIWA,
  },
  {
    id: 5,
    text: "منصة بلدي",
    img: "balady.png",
    endPoint: "balady",
    schema: DALADY,
    form: FORM_DALADY,
  },
  {
    id: 6,
    text: "منصة سلامة - الدفاع المدني",
    img: "salamah.png",
    endPoint: "salamah",
    schema: SALAMAH,
    form: FORM_SALAMAH,
  },
  {
    id: 7,
    text: "شركة  المياة الوطنية",
    img: "nwc.png",
    endPoint: "waters",
    schema: WATER,
    form: FORM_WATER,
  },
  {
    id: 8,
    text: "منصة صناعي",
    img: "nif.png",
    endPoint: "senaei",
    schema: MCI,
    form: FORM_MCI,
  },
  {
    id: 9,
    text: "هيئة الزكاة والدخل",
    img: "gaz.png",
    endPoint: "zakat",
    schema: ZAKAT,
    form: FORM_ZAKAT,
  },
  {
    id: 10,
    text: "منصة وزارة التجارة",
    img: "mci.png",
    endPoint: "mcgovs",
    schema: COM,
    form: FORM_COM,
  },
  {
    id: 11,
    text: "الغرفة التجارية",
    img: "rc.png",
    endPoint: "riyadh_chamber",
    schema: COMAMBER,
    form: FORM_COMAMBER,
  },
  {
    id: 12,
    text: "وزارة التجارة - السجل التجاري",
    img: "mci.png",
    endPoint: "commercial",
    schema: CI,
    form: FORM_CI,
  },
  {
    id: 13,
    text: "منصة مقيم",
    img: "muqeem.png",
    endPoint: "muqeem",
    schema: MUQEEM,
    form: FORM_MUQEEM,
  },
  {
    id: 14,
    text: "شركة الكهرباء",
    img: "sec.png",
    endPoint: "electricity",
    schema: ELECTRICITY,
    form: FORM_ELECTRICITY,
  },
  {
    id: 15,
    text: "منصة ناجز",
    img: "najiz.png",
    endPoint: "najis",
    schema: NAJIZ,
    form: FORM_NAJIZ,
  },
  {
    id: 16,
    text: "منصة هيئة المقاولين",
    img: "sca.png",
    endPoint: "sca",
    schema: CA,
    form: FORM_CA,
  },
  {
    id: 17,
    text: "منصة اعتماد",
    img: "etimad.png",
    endPoint: "etimad",
    schema: ETIMAD,
    form: FORM_ETIMAD,
  },
  {
    id: 18,
    text: "منصة مدن",
    img: "sipa.png",
    endPoint: "modon",
    schema: IPA,
    form: FORM_IPA,
  },
  {
    id: 19,
    text: "مواقف السيارات",
    img: "name.png",
    endPoint: "parking",
    schema: PARKING,
    form: FORM_PARKING,
  },
];
