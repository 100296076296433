import React from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { NavLink, useNavigate } from "react-router-dom";
import {
  setDefault,
  setEmail,
  setPassword,
  setSendForm,
} from "../../store/auth/form";
import { login } from "../../store/auth";
import { LoadingRipple } from "../dashboard/loader/Loading";
import Input from "../dashboard/inputs/Default";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { auth, sendForm } = useAppSelector((state) => state.authForm);
  const { isLoading } = useAppSelector((state) => state.auth);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (auth.email.length > 0 && auth.password.length > 5) {
      dispatch(login(auth)).then((res) => {
        if (res.payload.status) {
          navigate("/dashboard", { replace: true });
          dispatch(setDefault());
        }
      });
    } else {
      dispatch(setSendForm());
    }
  };

  return (
    <div className=" flex flex-col gap-10 py-6 px-4">
      <div className=" flex flex-col gap-1">
        <h1 className=" text-2xl font-bold">مرحباً بالعودة</h1>
        <p className=" text-sm">مرحباً بالعودة! من فضلك أدخل البيانات.</p>
      </div>
      <form className=" flex flex-col gap-5" onSubmit={submit}>
        <Input
          text={"البريد الإلكتروني"}
          type={"email"}
          placeholder={"name@ladun.com"}
          fun={setEmail}
          isError={auth.email.length === 0 && sendForm}
          alert={"من فضلك أدخل البريد الإلكتروني"}
        />
        <Input
          text={"كلمة المرور"}
          type={"password"}
          placeholder={"********"}
          fun={setPassword}
          isError={auth.password.length < 6 && sendForm}
          alert={"من فضلك أدخل كلمة المرور"}
        />
        <button
          type="submit"
          disabled={isLoading}
          className=" flex-1 flex gap-4 justify-center items-center mt-4 py-3 text-white text-lg bg-btn-auth hover:bg-btn-auth/90 rounded-md transition-all duration-150"
        >
          <p className=" text-xl">تسجيل الدخول</p>
          {isLoading && <LoadingRipple />}
        </button>
        <NavLink to="/">
          <p className=" text-sm font-bold text-menu-item hover:text-btn-auth transition-all duration-150 -my-2">هل تريد أختيار شركة أخري ؟</p>
        </NavLink>
        <NavLink to="restPassword">
          <p className=" text-sm font-bold text-menu-item hover:text-btn-auth transition-all duration-150 -my-2">تغيير كلمة المرور</p>
        </NavLink>
      </form>
    </div>
  );
};

export default Login;
