import React from "react";
import { NavLink } from "react-router-dom";
import Input from "../dashboard/inputs/Default";
import { LoadingRipple } from "../dashboard/loader/Loading";
import { useAppSelector } from "../../hooks/useAppSelector";
import { setEmail, setOTPCode, setPassword } from "../../store/auth/form";

const RestPassword: React.FC = () => {
  const { OTPCode } = useAppSelector((state) => state.auth);
  const { OTP, auth } = useAppSelector((state) => state.authForm);

  return (
    <div className=" flex flex-col gap-10 py-6 px-4">
      <div className=" flex flex-col gap-1">
        <h1 className=" text-2xl font-bold">مشكلة في تسجيل الدخول؟</h1>
        <p className=" text-sm">
          أدخل عنوان بريدك الإلكتروني، وسنرسل لك كود مكون من ستة ارقام.
        </p>
      </div>
      <form className=" flex flex-col gap-5">
        {OTPCode.length === 6 ? (
          OTPCode === OTP ? (
            <Input
              text={"كلمة المرور"}
              type={"password"}
              placeholder={"********"}
              fun={setPassword}
              isError={false}
              alert={"من فضلك أدخل كلمة المرور"}
            />
          ) : (
            <Input
              text={"الكود"}
              type={"text"}
              placeholder={"XXXXXX"}
              fun={setOTPCode}
              isError={OTP.length === 6 && OTP === OTPCode}
              alert={"من فضلك أدخل الكود المكون من ستة ارقام"}
            />
          )
        ) : (
          <Input
            text={"البريد الإلكتروني"}
            type={"email"}
            placeholder={"name@ladun.com"}
            fun={setEmail}
            isError={auth.email.length === 0}
            alert={"من فضلك أدخل البريد الإلكتروني"}
          />
        )}
        <button
          type="submit"
          disabled={false}
          className=" flex-1 flex gap-4 justify-center items-center mt-4 py-3 text-white text-lg bg-btn-auth hover:bg-btn-auth/90 rounded-md transition-all duration-150"
        >
          <p className=" text-xl">أرسل الرمز</p>
          {false && <LoadingRipple />}
        </button>
        <NavLink to="/login">
          <p className=" text-sm font-bold text-menu-item hover:text-btn-auth transition-all duration-150 -my-2">
            تسجيل الدخول
          </p>
        </NavLink>
      </form>
    </div>
  );
};

export default RestPassword;
