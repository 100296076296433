import React from "react";
import { LoadingRipple } from "../../components/dashboard/loader/Loading";
import Cookie from "cookie-universal";
import { setCloseFormEditStaff, setForm } from "../../store/staff/form";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { STAFF } from "../../schema/tables/staff/staff";
import { useValidate } from "../../hooks/useValidate";
import { useMsgInfo } from "../../toastify/notification";
import { updateItemStaff } from "../../store/staff";

const Profile = () => {
  const cookie = Cookie();
  const logo = cookie.get("logo_company");

  const messageInfo = useMsgInfo;
  const dispatch = useAppDispatch();
  const { staff } = useAppSelector((state) => state.staffForm);
  const Validate = useValidate(staff, STAFF);

  const submit = (indexItem?: number) => {
    if (Validate) {
      messageInfo(`أدخل ${Validate}`);
    } else {
      dispatch(updateItemStaff({ form: staff, indexItem })).then((res: any) => {
        if (res.payload.data.successful) {
          dispatch(setCloseFormEditStaff());
        }
      });
    }
  };

  return (
    <div className=" h-vh-minus-96 bg-white rounded-md py-3 px-3 lg:px-8 overflow-x-hidden overflow-y-scroll">
      <div className=" flex justify-center">
        <h1 className=" text-lg font-bold text-menu-item border-b border-dashed pb-1">
          الملف الشخصي
        </h1>
      </div>
      <form className=" grid grid-cols-12 gap-x-6 gap-y-4 py-6">
        <div className=" col-span-6 mb-4 flex flex-col gap-2">
          <p className=" text-[#6c757d] font-bold">الصورة الشخصية</p>
          <label className=" flex w-40 border p-4 cursor-pointer bg-[#eee] rounded-md">
            <img
              src="/media/img/avatar-staff.svg"
              alt=""
              className=" w-full h-full"
            />
            <input
              type="file"
              hidden
              className=" w-full h-full"
              onChange={(e: any) =>
                dispatch(
                  setForm({ name: "personal_photo", value: e.target.files[0] })
                )
              }
            />
          </label>
        </div>
        <div className=" hidden lg:flex col-span-6 justify-end items-center opacity-40">
          <div className=" w-48">
            <img
              src={`/media/img/companies/${logo}`}
              alt=""
              className=" w-full h-full"
            />
          </div>
        </div>
        <label className=" col-span-12 lg:col-span-6">
          <p className=" texxt-sm text-menu-item font-bold">{"الأسم الأول"}</p>
          <input
            type="text"
            placeholder={"الأسم الأول"}
            value={staff.first_name}
            className={` border-2 rounded-md outline-none w-full p-2 transition-all duration-150 focus:border-active-input-auth`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setForm({ name: "first_name", value: e.target.value }))
            }
          />
        </label>
        <label className=" col-span-12 lg:col-span-6">
          <p className=" texxt-sm text-menu-item font-bold">{"الأسم الثاني"}</p>
          <input
            type="text"
            placeholder={"الأسم الثاني"}
            value={staff.last_name}
            className={` border-2 rounded-md outline-none w-full p-2 transition-all duration-150 focus:border-active-input-auth`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setForm({ name: "last_name", value: e.target.value }))
            }
          />
        </label>
        <label className=" col-span-12 lg:col-span-6">
          <p className=" texxt-sm text-menu-item font-bold">{"الجنسية"}</p>
          <input
            type="text"
            placeholder={"الجنسية"}
            value={staff.nationality}
            className={` border-2 rounded-md outline-none w-full p-2 transition-all duration-150 focus:border-active-input-auth`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setForm({ name: "nationality", value: e.target.value }))
            }
          />
        </label>
        <label className=" col-span-12 lg:col-span-6">
          <p className=" texxt-sm text-menu-item font-bold">{"الإيميل"}</p>
          <input
            type="email"
            placeholder={"الإيميل"}
            value={staff.email}
            className={` border-2 rounded-md outline-none w-full p-2 transition-all duration-150 focus:border-active-input-auth`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setForm({ name: "email", value: e.target.value }))
            }
          />
        </label>
        <label className=" col-span-12 lg:col-span-6">
          <p className=" texxt-sm text-menu-item font-bold">{"الجوال"}</p>
          <input
            type="text"
            placeholder={"الجوال"}
            value={staff.phone_number}
            className={` border-2 rounded-md outline-none w-full p-2 transition-all duration-150 focus:border-active-input-auth`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setForm({ name: "phone_number", value: e.target.value }))
            }
          />
        </label>
        <label className=" col-span-12 lg:col-span-6">
          <p className=" texxt-sm text-menu-item font-bold">{"كلمة المرور"}</p>
          <input
            type="password"
            placeholder={"كلمة المرور"}
            value={staff.password}
            className={` border-2 rounded-md outline-none w-full p-2 transition-all duration-150 focus:border-active-input-auth`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setForm({ name: "password", value: e.target.value }))
            }
          />
        </label>
        <button
          type="submit"
          onSubmit={() => submit(0)}
          className=" col-span-12  flex gap-4 justify-center items-center text-white text-lg mt-4 py-3 bg-btn-auth hover:bg-btn-auth/90 rounded-md transition-all duration-150"
        >
          <p className=" text-xl">تعديل</p>
          {false && <LoadingRipple />}
        </button>
      </form>
    </div>
  );
};

export default Profile;
