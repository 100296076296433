import React from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../hooks/useAppSelector";
import DefaultSideBar from "../../components/dashboard/bars/DefaultSideBar";
import TopBar from "../../components/dashboard/bars/TopBar";
import useGaurd from "../../utils/useGaurd";

const LayoutVertical: React.FC = () => {
  const { showSidebarMobile, reSizeScrean } = useAppSelector(
    (state) => state.global
  );
  return (
    <div
      className=" grid grid-cols-12 w-screen min-h-screen font-Cairo overflow-hidden"
      dir="rtl"
    >
      {reSizeScrean ? (
        ""
      ) : showSidebarMobile ? (
        <div className=" lg:hidden fixed top-0 right-0 z-50 bg-white shadow-md shadow-[#ddd]">
          <DefaultSideBar />
        </div>
      ) : (
        ""
      )}

      {!reSizeScrean && (
        <div className=" hidden lg:flex lg:col-span-2 bg-white">
          <DefaultSideBar />
        </div>
      )}

      <div
        className={` col-span-12 bg-body ${
          reSizeScrean ? "" : "lg:col-span-10"
        } `}
      >
        <TopBar />
        <div className=" h-vh-minus-64 overflow-hidden">
          <div className="px-2 md:px-5 py-3">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useGaurd(LayoutVertical);
