type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  placeholder?: string;
  Cell?: ({ row }: { row: any }) => {};
};

type FORM = {
  commissioners_name: string | null;
  id_number: number | null;
  mandated_date: Date | null;
  mandated_expiry_date: Date | null;
  notes: string | null;
  purpose_contract: string | null;
  duration: string | null;
  start_date: Date | null;
  end_date: Date | null;
  note: string | null;
  ejar_file: File[] | null;
};

export const EJAR: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
    // Cell: ({ row }) => {
    //   const numRow = Number(row.id) + 1;
    //   return numRow;
    // }
  },
  {
    Header: "اسم المفوض",
    accessor: "commissioners_name",
    type: "text",
    placeholder: "اسم المفوض",
  },
  {
    Header: "رقم الهوية",
    accessor: "id_number",
    type: "text",
    placeholder: "رقم الهوية",
  },
  {
    Header: "تاريخ التفويض",
    accessor: "mandated_date",
    type: "date",
  },
  {
    Header: "تاريخ انتهاء التفويض",
    accessor: "mandated_expiry_date",
    type: "date",
  },
  {
    Header: "الملاحظات",
    accessor: "notes",
    type: "text",
    placeholder: "ملاحظة",
  },
  {
    Header: "الغرض من العقد",
    accessor: "purpose_contract",
    type: "text",
    placeholder: "الغرض من العقد",
  },
  {
    Header: "مدته",
    accessor: "duration",
    type: "text",
    placeholder: "مدته",
  },
  {
    Header: "تاريخ البداية",
    accessor: "start_date",
    type: "date",
  },
  {
    Header: "تاريخ  الانتهاء ",
    accessor: "end_date",
    type: "date",
  },
  {
    Header: "الملاحظات",
    accessor: "note",
    type: "text",
    placeholder: "ملاحظة",
  },
  {
    Header: "المرفقات",
    accessor: "ejar_file",
    type: "file",
  },
];

export const FORM_EJAR: FORM = {
  commissioners_name: null,
  id_number: null,
  mandated_date: null,
  mandated_expiry_date: null,
  notes: null,
  purpose_contract: null,
  duration: null,
  start_date: null,
  end_date: null,
  note: null,
  ejar_file: null,
};
