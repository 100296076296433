type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  placeholder?: string;
  Cell?: ({ row }: { row: any }) => {};
};

type FORM = {
  commissioners_name: string | null;
  id_number: number | null;
  mandated_date: Date | null;
  mandated_expiry_date: Date | null;
  notes: string | null;
  etimad_file: File[] | null;
};

export const ETIMAD: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
    // Cell: ({ row }) => {
    //   const numRow = Number(row.id) + 1;
    //   return numRow;
    // },
  },
  {
    Header: "اسم المفوض",
    accessor: "commissioners_name",
    type: "text",
    placeholder: "اسم المفوض",
  },
  {
    Header: "رقم الهوية",
    accessor: "id_number",
    type: "text",
    placeholder: "رقم الهوية",
  },
  {
    Header: "تاريخ التفويض",
    accessor: "mandated_date",
    type: "date",
  },
  {
    Header: "تاريخ انتهاء التفويض",
    accessor: "mandated_expiry_date",
    type: "date",
  },
  {
    Header: "الملاحظات",
    accessor: "notes",
    type: "text",
    placeholder: "ملاحظة",
  },
  {
    Header: "المرفقات",
    accessor: "etimad_file",
    type: "file",
  },
];

export const FORM_ETIMAD: FORM = {
  commissioners_name: null,
  id_number: null,
  mandated_date: null,
  mandated_expiry_date: null,
  notes: null,
  etimad_file: null,
};
