import { createSlice } from "@reduxjs/toolkit";

interface permissionForm {
  roles: any;
  isShowFormAddPermission: boolean;
  isShowFormEditPermission: { status: boolean; id: number | null };
  field: string;
}

const initialState: permissionForm = {
  roles: {
    name: "",
    permission: [],
  },
  isShowFormAddPermission: false,
  isShowFormEditPermission: { status: false, id: null },
  field: "",
};

const permissionFormSlice = createSlice({
  name: "permissionFormSlice",
  initialState,
  reducers: {
    setOpenFormAddPermission: (state) => {
      state.isShowFormAddPermission = true;
      state.field = "addMode";
      state.roles.id = null;
      state.roles.name = "";
      state.roles.permission = [];
    },
    setCloseFormAddPermission: (state) => {
      state.field = "";
      state.isShowFormAddPermission = false;
      state.roles.name = "";
      state.roles.permission = [];
    },
    setOpenFormEditPermission: (state: any, action) => {
      state.isShowFormEditPermission.status = true;
      state.isShowFormEditPermission.id = action.payload.id;
      state.roles.id = action.payload.id;
      state.roles.name = action.payload.name;
      state.roles.permission = [];
      action.payload.permissions.map((item: any) =>
        state.roles.permission.push(item.name)
      );
      state.field = "editMode";
    },
    setCloseFormEditPermission: (state) => {
      state.field = "";
      state.isShowFormEditPermission.status = false;
      state.isShowFormEditPermission.id = null;
      state.roles.name = "";
      state.roles.permission = [];
    },
    setFormData: (state, action) => {
      const { name, value, check } = action.payload;
      if (name === "name") {
        state.roles.name = value;
      } else {
        check
          ? state.roles.permission.push(value)
          : (state.roles.permission = state.roles.permission.filter(
              (role: any) => role !== value
            ));
      }
    },
  },
  extraReducers: {},
});

export default permissionFormSlice.reducer;
export const {
  setOpenFormEditPermission,
  setCloseFormAddPermission,
  setOpenFormAddPermission,
  setCloseFormEditPermission,
  setFormData,
} = permissionFormSlice.actions;
