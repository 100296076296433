
export const useValidate = (form: any, row: any) => {
  for (let key in form) {
    if ((form[key] === null || form[key]?.length === 0 ) && key !== 'personal_photo') {
      const obj: any = row.filter((item: any) => item.accessor === key);
      return obj[0].Header;
    }
  }
  return false;
};
