import React from "react";
import Cookie from "cookie-universal";
import { Outlet } from "react-router-dom";

const Auth: React.FC = () => {
  const cookie = Cookie();
  const logo = cookie.get("logo_company");

  return (
    <div className=" h-screen w-screen flex items-center py-5 px-2 md:py-16 md:px-8">
      <div className=" container max-w-5xl h-fit flex flex-col-reverse md:flex-row rounded-md overflow-hidden shadow-box-2">
        <div className=" flex-[55%] bg-white md:bg-white/50">
          <Outlet />
        </div>
        <div className=" lg:flex-[45%] flex justify-center items-center bg-f3f4f8 relative select-none">
          <p className=" absolute top-2 left-2 text-muted text-lg" dir="ltr">
            1.0.0 v
          </p>
          <div className=" w-40 lg:w-60">
            <img
              className=" w-full"
              src={`./media/img/companies/${logo}`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
