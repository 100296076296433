import {
  faArrowDown,
  faCloudArrowDown,
  faEye,
  faFile,
  faFloppyDisk,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useDropzone } from "react-dropzone";
import { useMsgInfo, useMsgSuccess } from "../../../toastify/notification";
import JSZip from "jszip";
import * as FileSaver from "file-saver";
import {
  btnDeleteFiles,
  setFilesDetails,
  setFormAdministrative,
} from "../../../store/administrative/form";
import { deleteFilesPlatform } from "../../../store/administrative";

interface props {
  fun?: any;
  name?: any;
}
const FileUpload: React.FC<props> = ({ fun, name }) => {
  const msgInfo = useMsgInfo;
  const msgSuccess = useMsgSuccess;
  const dispatch = useAppDispatch();
  const { filesDetails, filename, platform, administrative } = useAppSelector(
    (state) => state.administrativeForm
  );

  const [files, setFiles] = useState<any[]>([]);
  const [clearFiles, setClearFiles] = useState<boolean>(false);

  const handlerFiles = () => {
    if (files.length === 0) {
      msgInfo("يجب اختيار مرفقات");
    } else {
      dispatch(setFormAdministrative({ name: filename, value: files }));
      msgSuccess("تم حفظ المرفقات");
    }
  };

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any) => {
    if (acceptedFiles?.length) {
      setFiles((previousfiles) => [
        ...previousfiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }

    rejectedFiles.errors.map((error: any) => msgInfo(error.message));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 2 });

  const handleDownload = () => {
    const zip = new JSZip();

    filesDetails.data.forEach((item: any, index: number) => {
      const extension = item.split(".")[item.split(".").length - 1];
      const nameFile = `file${index + 1}.${extension}`;
      zip.file(nameFile, item);
    });

    zip.generateAsync({ type: "blob" }).then((content: any) => {
      FileSaver.saveAs(content, "files.zip");
    });
  };

  const removeFile = (name: any) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  const item = filesDetails.data.map((item: any) => (
    <li
      key={item}
      className=" relative bg-muted flex h-14 w-fit md:w-96 p-1 rounded-md items-center justify-between px-4 shadow-box-2"
    >
      <div className=" absolute -top-2 -right-2 flex gap-1">
        {/* <div className=" w-6 h-6 text-sm flex justify-center items-center rounded-full cursor-pointer text-white bg-[#24c732]/90 shadow-box-2">
          <FontAwesomeIcon icon={faArrowDown} />
        </div> */}
        <a
          href={`https://api.ladunadminservices.com/storage/uploads/${filename}/${item}`}
          target="_blank"
          className=" w-6 h-6 text-sm flex justify-center items-center rounded-full cursor-pointer text-white bg-[#71b6f9]/90 shadow-box-2"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faEye} />
        </a>
      </div>
      <p className=" max-w-[250px] overflow-hidden">{item}</p>
      <div className=" w-16 h-10 rounded-md overflow-hidden">
        <img
          className=" w-full h-full"
          src={`https://api.ladunadminservices.com/storage/uploads/${filename}/${item}`}
          alt=""
        />
      </div>
    </li>
  ));

  return (
    <div className=" fixed top-0 left-0 z-50 p-12 bg-[#ffffff80] blur-1 w-screen h-screen flex justify-center items-center">
      <div className=" relative w-full h-full rounded-md border border-dashed border-black bg-eee flex flex-col">
        <div
          className=" absolute -top-4 -right-4 shadow-box-2 border border-black border-dashed bg-white w-9 h-9 cursor-pointer rounded-full flex justify-center items-center text-lg text-error"
          onClick={() =>
            dispatch(setFilesDetails({ data: [], file: "", showModal: false }))
          }
        >
          <FontAwesomeIcon icon={faXmark} beat />
        </div>
        <div className=" py-2 text-sm md:text-xl font-bold text-black">
          <p>
            المرفقات
            <span className=" text-muted text-sm" dir="ltr">
              (.pdf, .jpg, .png, .word, .xl)
            </span>
          </p>
        </div>
        {(filesDetails.filed === "add" || filesDetails.filed === "edit") && (
          <div className=" flex justify-evenly py-4 px-4">
            <label
              className=" w-full max-w-xl h-20 md:h-40 flex items-center justify-center gap-2 border border-dashed border-muted hover:border-[#71b6f9] py-2 px-2 text-muted hover:text-[#71b6f9] rounded-md cursor-pointer flex-col text-sm md:text-lg"
              {...getRootProps({})}
            >
              <p> قم بالسحب والإفلات أو الضغط هنا</p>
              <input {...getInputProps()} />
            </label>
          </div>
        )}
        <div className=" px-4 py-2 overflow-y-scroll">
          <ul className=" flex flex-col gap-3 items-center">
            {files.map((file) => (
              <li
                key={file.name}
                className=" relative shadow-box-2 bg-muted flex h-14 w-fit md:w-96 p-1 rounded-md items-center justify-between px-4"
              >
                <div
                  className=" absolute w-6 h-6 rounded-full -top-2 -right-2 cursor-pointer text-white bg-error"
                  onClick={() => {
                    removeFile(file.name);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
                <p>{file.name}</p>
                <div className=" w-16 h-10 rounded-md overflow-hidden">
                  {file.name.split(".")[file.name.split(".").length - 1] ===
                  ("xlsx" || "xls") ? (
                    <FontAwesomeIcon icon={faFile} />
                  ) : (
                    <img className=" w-full h-full" src={file.preview} alt="" />
                  )}
                </div>
              </li>
            ))}
            {item}
          </ul>
        </div>
        <div className=" flex flex-col md:flex-row justify-center items-center gap-2 md:gap-6 py-4">
          {((filesDetails.filed === "add" || filesDetails.filed === "edit") && files.length > 0) && (
            <button
              onClick={handlerFiles}
              className=" flex items-center justify-center gap-2 w-40 bg-[#71b6f9]/90 py-2 px-3 hover:opacity-70 text-white text-lg rounded-md cursor-pointer"
            >
              <p>حفظ</p>
              <FontAwesomeIcon icon={faFloppyDisk} />
            </button>
          )}
          {(filesDetails.filed === "edit" && filesDetails.data.length > 0) && (
            <button
              className=" flex items-center justify-center gap-2 w-40 bg-error/90 py-2 px-3 hover:opacity-70 text-white text-lg rounded-md cursor-pointer"
              onClick={() =>
                dispatch(
                  deleteFilesPlatform({
                    id: administrative.id,
                    endPoint: platform.endPoint,
                    filename: filename,
                  })
                ).then((res: any) => {
                  if (res.payload.data.message) {
                    dispatch(btnDeleteFiles())
                  }
                })
              }
            >
              <p>حذف</p>
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          )}
          {/* {filesDetails.filed === "view" && (
            <button
              onClick={handleDownload}
              className=" flex items-center justify-center gap-2 w-40 bg-[#24c732]/90 py-2 px-3 hover:opacity-70 text-white text-lg rounded-md cursor-pointer"
              rel="noreferrer"
            >
              <p>تحميل الكل</p>
              <FontAwesomeIcon icon={faCloudArrowDown} />
            </button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FileUpload);
