import { createSlice } from "@reduxjs/toolkit";
import { COMPANIES } from "../../schema/tables/Administrative/companies";

interface state {
  showSidebarMobile: boolean;
  showSetting: boolean;
  showUserInfo: boolean;
  showBell: boolean;
  showSearch: boolean;
  dropMenu: boolean;
  selectCompany: string[];
  endPointApi: string;
  nameAdministrativeCompany: string;
  loadingCompany: boolean;
  isShowFormAdministrative: boolean;
  showUploadFiles: boolean;
  reSizeScrean: boolean;
}

const initialState: state = {
  showSidebarMobile: false,
  showSetting: false,
  showUserInfo: false,
  showBell: false,
  showSearch: false,
  dropMenu: false,
  selectCompany: [],
  endPointApi: "",
  nameAdministrativeCompany: "",
  loadingCompany: false,
  isShowFormAdministrative: false,
  showUploadFiles: false,
  reSizeScrean: false,
};

const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    setShowSidebarMobile: (state) => {
      state.showSidebarMobile = !state.showSidebarMobile;
    },
    setShowSetting: (state) => {
      state.showSetting = !state.showSetting;
    },
    setShowUserInfo: (state) => {
      state.showUserInfo = !state.showUserInfo;
    },
    setShowBell: (state) => {
      state.showBell = !state.showBell;
    },
    setShowSearch: (state) => {
      state.showSearch = !state.showSearch;
    },
    setDropMenu: (state) => {
      state.dropMenu = !state.dropMenu;
    },
    setOpenFormAddAdministrative:(state) => {
      state.isShowFormAdministrative = true;
    },
    setCloseFormAddAdministrative: (state) => {
      state.isShowFormAdministrative = false;
    },
    setShowUploadFiles: (state) => {
      state.showUploadFiles = !state.showUploadFiles;
    },
    setReSizeScrean: (state) => {
      state.reSizeScrean = !state.reSizeScrean;
    },
    setSelectCompany: (
      state,
      action: { payload: { id: any; endpoint: any; nameCompany: string } }
    ) => {
      state.loadingCompany = true;
      state.isShowFormAdministrative = false;
      const schema: any = COMPANIES.find(
        (item) => item.id === action.payload.id
      );
      state.selectCompany = schema.schema;
      state.endPointApi = action.payload.endpoint;
      state.nameAdministrativeCompany = action.payload.nameCompany;
    },
    setRemoveShowCompany: (state) => {
      state.selectCompany = [];
      state.endPointApi = "";
      state.nameAdministrativeCompany = "";
    },
    setLoadingCompany: (state) => {
      state.loadingCompany = false;
    },
  },
});

export default globalSlice.reducer;
export const {
  setShowSidebarMobile,
  setShowSetting,
  setShowUserInfo,
  setShowBell,
  setShowSearch,
  setDropMenu,
  setSelectCompany,
  setLoadingCompany,
  setRemoveShowCompany,
  setOpenFormAddAdministrative,
  setCloseFormAddAdministrative,
  setShowUploadFiles,
  setReSizeScrean
} = globalSlice.actions;
