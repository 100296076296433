type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  placeholder?: string;
  Cell?: ({ row }: { row: any }) => {};
};

type FORM = {
  name: string | null;
  job_title: string | null;
  office_number: number | null;
  position_number: number | null;
  notes: string | null;
  parking_file: File[] | null;
};

export const PARKING: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
    // Cell: ({ row }) => {
    //   const numRow = Number(row.id) + 1;
    //   return numRow;
    // },
  },
  {
    Header: "الاسم",
    accessor: "name",
    type: "text",
    placeholder: "الاسم",
  },
  {
    Header: "المسمي الوظيفي",
    accessor: "job_title",
    type: "text",
    placeholder: "الاسم",
  },
  {
    Header: "رقم الموظف",
    accessor: "office_number",
    type: "text",
    placeholder: "الاسم",
  },
  {
    Header: "رقم المكتب",
    accessor: "position_number",
    type: "text",
    placeholder: "الاسم",
  },
  {
    Header: "الملاحظات",
    accessor: "notes",
    type: "text",
    placeholder: "الاسم",
  },
  {
    Header: "المرفقات",
    accessor: "parking_file",
    type: "file",
  },
];

export const FORM_PARKING: FORM = {
  name: null,
  job_title: null,
  office_number: null,
  position_number: null,
  notes: null,
  parking_file: null,
};
