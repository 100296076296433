import { createSlice } from "@reduxjs/toolkit";

type from = {
  email: string;
  password: string;
};

interface authForm {
  sendForm: boolean;
  auth: from;
  OTP: string;
}

const initialState: authForm = {
  sendForm: false,
  auth: {
    email: "",
    password: "",
  },
  OTP: "",
};

const authFormSlice = createSlice({
  name: "authFormSlice",
  initialState,
  reducers: {
    setSendForm: (state) => {
      state.sendForm = true;
    },
    setEmail: (state, actions: { payload: string }) => {
      state.auth.email = actions.payload;
    },
    setPassword: (state, action: { payload: string }) => {
      state.auth.password = action.payload;
    },
    setOTPCode: (state, action) => {
      state.OTP = action.payload;
    },
    setDefault: (state) => {
      state.sendForm = false;
      state.auth.email = "";
      state.auth.password = "";
    },
  },
  extraReducers: {},
});

export default authFormSlice.reducer;
export const { setEmail, setPassword, setSendForm, setDefault, setOTPCode } =
  authFormSlice.actions;
