type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  placeholder?: string;
  Cell?: ({ row }: { row: any }) => {};
};

type FORM = {
  commissioners_name: string | null;
  id_number: number | null;
  mandated_date: Date | null;
  mandated_expiry_date: Date | null;
  notes: string | null;
  subscription_number: number | null;
  location: string | null;
  account_number: number | null;
  subscriber_name: number | null;
  note: string | null;
  electricity_file: File[] | null;
};

export const ELECTRICITY: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
    // Cell: ({ row }) => {
    //   const numRow = Number(row.id) + 1;
    //   return numRow;
    // },
  },
  {
    Header: "اسم المفوض",
    accessor: "commissioners_name",
    type: "text",
    placeholder: "اسم المفوض",
  },
  {
    Header: "رقم الهوية",
    accessor: "id_number",
    type: "text",
    placeholder: "رقم الهوية",
  },
  {
    Header: "تاريخ التفويض",
    accessor: "mandated_date",
    type: "date",
  },
  {
    Header: "تاريخ انتهاء التفويض",
    accessor: "mandated_expiry_date",
    type: "date",
  },
  {
    Header: "الملاحظات",
    accessor: "notes",
    type: "text",
    placeholder: "ملاحظة",
  },
  {
    Header: "رقم الاشتراك",
    accessor: "subscription_number",
    type: "text",
    placeholder: "رقم الاشتراك",
  },
  {
    Header: "الموقع",
    accessor: "location",
    type: "text",
    placeholder: "الموقع",
  },
  {
    Header: "رقم الحساب",
    accessor: "account_number",
    type: "text",
    placeholder: "رقم الحساب",
  },
  {
    Header: "اسم المشترك",
    accessor: "subscriber_name",
    type: "text",
    placeholder: "اسم المشترك",
  },
  {
    Header: "الملاحظات",
    accessor: "note",
    type: "text",
    placeholder: "ملاحظة",
  },
  {
    Header: "المرفقات",
    accessor: "electricity_file",
    type: "file",
  },
];

export const FORM_ELECTRICITY: FORM = {
  commissioners_name: null,
  id_number: null,
  mandated_date: null,
  mandated_expiry_date: null,
  notes: null,
  subscription_number: null,
  location: null,
  account_number: null,
  subscriber_name: null,
  note: null,
  electricity_file: null,
};
