import React from "react";
import LeftMenu from "./LeftMenu";
import Cookie from "cookie-universal";

const DefaultSideBar: React.FC = () => {
  const cookie = Cookie();
  const logo = cookie.get("logo_company");
  return (
    <div className="px-2 pt-2 pb-4 flex-1 h-screen overflow-y-scroll overflow-x-hidden relative">
      <div className=" w-44 mx-auto">
        <img
          src={`/media/img/companies/${logo}`}
          alt=""
          className=" w-full h-full"
        />
      </div>
      <div className=" w-100 flex flex-col justify-center items-start gap-2 my-6 mx-2">
        <div>
          <LeftMenu />
        </div>
      </div>
    </div>
  );
};

export default DefaultSideBar;
