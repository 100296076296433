import Swal from "sweetalert2";

const useDeleteItem = (fun: any) => {
  Swal.fire({
    title: " هل أنت متأكد!",
    text: "في حالة الحذف لن تتمكن من العودة إلي هذا.",
    imageUrl: "/trash.svg",
    imageWidth: 100,
    showCancelButton: true,
    confirmButtonColor: "#D9003F",
    cancelButtonColor: "#4A5CB6",
    confirmButtonText: "نعم",
    cancelButtonText: "لا",
  }).then((result) => {
    if (result.isConfirmed) {
      fun();
    }
  });
};

export default useDeleteItem;
