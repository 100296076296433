import React from "react";
import Table from "../tables/Table";
import Button from "../buttons/Default";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faShare } from "@fortawesome/free-solid-svg-icons";
import { LoadingHourglass } from "../loader/Loading";
import { setRemoveShowCompany } from "../../../store/global";
import { removeData } from "../../../store/administrative";

type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  cell?: ({ value }: { value: any }) => {};
};

interface props {
  columns: any[];
  data: any[];
  loading: boolean;
  ShowFormAdd: boolean;
  openFormAdd: any;
  children: any;
  btnBackPage: any;
  inputs?: any[];
  showFormEdit?: any;
  closeFormEdit?: any;
  funEdit?: any;
  itemData?: any;
  submit?: any;
  rules?: any;
  list?: any;
}

const PlanCompanies: React.FC<props> = ({
  columns,
  data,
  loading,
  ShowFormAdd,
  openFormAdd,
  children,
  btnBackPage,
  inputs,
  showFormEdit,
  closeFormEdit,
  funEdit,
  itemData,
  submit,
  rules,
  list,
}) => {
  return (
    <div className=" w-full bg-white rounded-md h-vh-minus-96">
      <div className=" flex items-center gap-1 py-1 px-2">
        {btnBackPage && (
          <Button
            icon={<FontAwesomeIcon icon={faShare} />}
            text={"رجوع"}
            customStyle={"bg-[#6c757d] py-1 px-2 rounded-md"}
            fun={btnBackPage}
          />
        )}
        <div className=" flex justify-between">
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            text={"إضافة"}
            customStyle={"bg-[#71b6f9] py-1 px-2 rounded-md"}
            fun={[openFormAdd]}
          />
        </div>
      </div>
      <div>
        {loading ? (
          <div className=" w-full h-vh-minus-96 flex justify-center items-center">
            <LoadingHourglass />
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            rules={rules}
            inputs={inputs}
            showFormEdit={showFormEdit}
            closeFormEdit={closeFormEdit}
            funEdit={funEdit}
            itemData={itemData}
            submit={submit}
            list={list}
          >
            {ShowFormAdd && children}
          </Table>
        )}
      </div>
    </div>
  );
};

export default PlanCompanies;
