import React from "react";
import "./loading.css";

export const LoadingHourglass: React.FC = () => {
  return <div className="lds-hourglass"></div>;
};

export const LoadingRipple: React.FC = () => {
  return (
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  );
};
