import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import LayoutVertical from "./pages/dashboard/LayoutVertical";
import Dashboard from "./pages/dashboard/Dashboard";
import store from "./store";
import { Provider } from "react-redux";
import Staff from "./pages/dashboard/Staff";
import Administrative from "./pages/dashboard/Administrative";
import RecruitmentAndTalent from "./pages/dashboard/RecruitmentAndTalent";
import TrainingAndDevelopment from "./pages/dashboard/TrainingAndDevelopment";
import PerformanceEvaluation from "./pages/dashboard/PerformanceEvaluation";
import Auth from "./pages/auth/Auth";
import Permission from "./pages/dashboard/Permission";
import { ToastContainer } from "react-toastify";
import Profile from "./pages/dashboard/Profile";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Support from "./pages/dashboard/Support";
import SelectCompany from "./pages/SelectCompany";
import Login from "./components/auth/Login";
import RestPassword from "./components/auth/RestPassword";

const routes = createBrowserRouter([
  {
    path: "/",
    errorElement: "error_page",
    element: <SelectCompany/>
  },
  {
    path: "/dashboard",
    element: <LayoutVertical />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "permission", element: <Permission /> },
      { path: "staff", element: <Staff /> },
      { path: "administrative_affairs", element: <Administrative /> },
      { path: "recruitment_talent", element: <RecruitmentAndTalent /> },
      { path: "training_development", element: <TrainingAndDevelopment /> },
      { path: "performance_evaluation", element: <PerformanceEvaluation /> },
      { path: "support", element: <Support /> },
      { path: "profile", element: <Profile /> },
    ],
  },
  {
    path: "/login",
    element: <Auth />,
    children: [
      {index: true, element: <Login />},
      {path: "restPassword", element: <RestPassword />}
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={routes} />
      <ToastContainer style={{ zIndex: "1000000000", right: "0" }} />
    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
