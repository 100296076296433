import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "cookie-universal";
import { useMsgInfo, useMsgSuccess } from "../../toastify/notification";

const cookie = Cookie();

type form = {
  name: string;
  permission: string[];
};

// [1] => Get All Items.
export const getAllItemsPermission = createAsyncThunk(
  "permissionSlice/getAll",
  async (numPage: number, thunkAPI) => {
    try {
      const res = await axios
        .get(`http://api.ladunadminservices.com/api/dashboard/roles`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Create Item.
export const createItemPermission = createAsyncThunk(
  "permissionSlice/create",
  async (userData: form, thunkAPI) => {
    try {
      const res = await axios
        .post(
          `http://api.ladunadminservices.com/api/dashboard/roles`,
          userData,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Update Item.
export const updateItemPermission = createAsyncThunk(
  "permissionSlice/update",
  async (userData: any, thunkAPI) => {
    try {
      const res = await axios
        .post(
          `http://api.ladunadminservices.com/api/dashboard/roles/${userData.form.id}`,
          userData.form,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return {data,indexItem: userData.indexItem};
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [4] => Delete Item.
export const deleteItemPermission = createAsyncThunk(
  "permissionSlice/delete",
  async (userId: number, thunkAPI) => {
    try {
      await axios.delete(
        `http://api.ladunadminservices.com/api/dashboard/roles/${userId}`,
        {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        }
      );

      return userId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface permission {
  rulesData: any[];
  isLoading: boolean;
}

const initialState: permission = {
  rulesData: [],
  isLoading: false,
};

const permissionSlice = createSlice({
  name: "permissionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] => Get All Items.
    builder.addCase(getAllItemsPermission.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(getAllItemsPermission.fulfilled, (state, action): void => {
      state.isLoading = false;
      state.rulesData = action.payload.data;
    });
    builder.addCase(getAllItemsPermission.rejected, (state, action) => {
      state.isLoading = false;
      useMsgInfo("يوجد مشكلة في السيرفر");
    });

    // [2] => Create Item.
    builder.addCase(createItemPermission.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(createItemPermission.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.message) {
        state.rulesData.push(action.payload.data);
        useMsgSuccess("تم الإضافة بنجاح");
      }
    });
    builder.addCase(createItemPermission.rejected, (state, action) => {
      state.isLoading = false;
      useMsgInfo("يوجد مشكلة في السيرفر");
    });

    // [3] => Update Item.
    builder.addCase(updateItemPermission.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(updateItemPermission.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.data.message) {
        state.rulesData[action.payload.indexItem] = action.payload.data.data;
        useMsgSuccess("تم التعديل بنجاح");
      }
    });
    builder.addCase(updateItemPermission.rejected, (state, action): void => {
      state.isLoading = false;
      useMsgInfo("يوجد مشكلة في السيرفر");
    });

    // [4] => Delete Item.
    builder.addCase(deleteItemPermission.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(deleteItemPermission.fulfilled, (state, action): void => {
      state.isLoading = false;
      state.rulesData = state.rulesData.filter(
        (item) => item.id !== action.payload
      );
      useMsgSuccess("تم الحذف بنجاح");
    });
    builder.addCase(deleteItemPermission.rejected, (state, action): void => {
      state.isLoading = false;
      useMsgInfo("يوجد مشكلة في السيرفر");
    });
  },
});

export default permissionSlice.reducer;
export const {} = permissionSlice.actions;
