import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "cookie-universal";
import {
  useMsgError,
  useMsgInfo,
  useMsgSuccess,
} from "../../toastify/notification";
const cookie = Cookie();

// [1] => Get All Items.
export const getAllItemsStaff = createAsyncThunk(
  "staffSlice/getAll",
  async (numPage: number, thunkAPI) => {
    try {
      const res = await axios
        .get(
          `http://api.ladunadminservices.com/api/dashboard/users?page=${numPage}`,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Create Item.
export const createItemStaff = createAsyncThunk(
  "staffSlice/create",
  async (item: any, thunkAPI) => {
    const formData = new FormData();
    for (let key in item) {
      formData.append(key, item[key]);
    }
    try {
      const res = await axios
        .post(
          `https://api.ladunadminservices.com/api/dashboard/users`,
          formData,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Update Item.
export const updateItemStaff = createAsyncThunk(
  "staffSlice/update",
  async (userData: any, thunkAPI) => {
    const formData = new FormData();
    for (let key in userData.form) {
      formData.append(key, userData.form[key]);
    }
    try {
      const res = await axios
        .post(
          `https://api.ladunadminservices.com/api/dashboard/users/${userData.form.id}`,
          formData,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return { data, indexItem: userData.indexItem };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [4] => Delete Item.
export const deleteItemStaff = createAsyncThunk(
  "staffSlice/delete",
  async (itemId: number, thunkAPI) => {
    try {
      await axios.delete(
        `http://api.ladunadminservices.com/api/dashboard/users/${itemId}`,
        {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        }
      );

      return itemId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [5] => Get Single Item.
export const getSingleItemStaff = createAsyncThunk(
  "staffSlice/getItem",
  async (id: number, thunkAPI) => {
    try {
      const res = await axios
        .get(`http://api.ladunadminservices.com/api/dashboard/users/${id}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface staff {
  staffData: any[];
  singleItemStsff: any;
  isLoading: boolean;
}

const initialState: staff = {
  staffData: [],
  singleItemStsff: {},
  isLoading: false,
};

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] => Get All Staff.
    builder.addCase(getAllItemsStaff.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(getAllItemsStaff.fulfilled, (state, action): void => {
      state.isLoading = false;
      state.staffData = action.payload.data;
    });
    builder.addCase(getAllItemsStaff.rejected, (state, action) => {
      state.isLoading = true;
      useMsgError("يوجد مشكلة في السيرفر");
    });

    // [2] => Create Staff.
    builder.addCase(createItemStaff.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(createItemStaff.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.staffData.push(action.payload.data);
        useMsgSuccess("تم الإضافة بنجاح");
      } else {
        if (action.payload.errors.email !== undefined) {
          useMsgError("الإيميل مستخدم من قبل");
        } else if (action.payload.errors.phone_number !== undefined) {
          useMsgError("يجب أن يتكون الجوال من 10 أحرف على الأقل.");
        }
      }
    });
    builder.addCase(createItemStaff.rejected, (state, action) => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في السيرفر");
    });

    // [3] => Update Staff.
    builder.addCase(updateItemStaff.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(updateItemStaff.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.data.successful) {
        state.staffData[action.payload.indexItem] = action.payload.data.data;
        useMsgSuccess("تم التعديل بنجاح");
      } else {
        if (action.payload.data.errors === "email") {
          useMsgError("الإيميل مستخدم من قبل");
        } else if (action.payload.data.errors === "phone_number") {
          useMsgError("يجب أن يتكون الجوال من 10 أحرف على الأقل.");
        }
      }
    });
    builder.addCase(updateItemStaff.rejected, (state, action): void => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في السيرفر");
    });

    // [4] => Delete Staff.
    builder.addCase(deleteItemStaff.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(deleteItemStaff.fulfilled, (state, action): void => {
      state.isLoading = false;
      state.staffData = state.staffData.filter(
        (item) => item.id !== action.payload
      );
      useMsgSuccess("تم الحذف بنجاح");
    });
    builder.addCase(deleteItemStaff.rejected, (state, action): void => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في السيرفر");
    });

    // [5] => Get Single Item.
    builder.addCase(getSingleItemStaff.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(getSingleItemStaff.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.singleItemStsff = action.payload.data;
      }
    });
    builder.addCase(getSingleItemStaff.rejected, (state, action): void => {
      state.isLoading = false;
      useMsgError("يوجد مشكلة في السيرفر");
    });
  },
});

export default staffSlice.reducer;
export const {} = staffSlice.actions;
