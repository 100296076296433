import React from "react";
import { NavLink } from "react-router-dom";
import Cookie from "cookie-universal";

const SelectCompany: React.FC = () => {
  const COMPANIES = [
    {
      id: 0,
      name: "لدن للإستثمار",
      img: "ladun.png",
    },
    {
      id: 1,
      name: "بانيت للصيانة والتشغيل",
      img: "painite.png",
    },
    {
      id: 2,
      name: "دايمات للتصنيع",
      img: "daymat.png",
    },
    {
      id: 3,
      name: "أسماء التقنية لتقنية المعلومات",
      img: "asmatech.png",
    },
    {
      id: 4,
      name: "بلت لتقنية البناء",
      img: "bicf.png",
    },
    {
      id: 5,
      name: "بلت للطاقة المتجدده",
      img: "brec.png",
    },
    {
      id: 6,
      name: "بلت المتخصصه للسلامة",
      img: "name.png",
    },
    {
      id: 7,
      name: " بلت الصناعية",
      img: "built.png",
    },
    {
      id: 8,
      name: "أوبال للحراسات الأمنية",
      img: "opal.png",
    },
    {
      id: 9,
      name: "إكتساب للموارد البشرية",
      img: "iktissab.png",
    },
    {
      id: 10,
      name: "بلت التجارية",
      img: "btc.png",
    },
    {
      id: 11,
      name: "بلت الالمنيوم",
      img: "bafco.png",
    },
    {
      id: 12,
      name: "بلت للحوائط مسبقة الصنع",
      img: "pco.png",
    },
  ];

  const cookie = Cookie();

  const handleCompany = (img: string) => {
    cookie.set("logo_company", img);
  }

  const COMPANY = COMPANIES.map((item) => (
    <NavLink
      to="/login"
      className=" col-span-12 md:col-span-4 lg:col-span-3 bg-white/40 rounded-md py-3 px-2 flex flex-col justify-between items-center cursor-pointer hover:bg-eee"
      key={item.id}
      onClick={() => handleCompany(item.img)}
    >
      <div className=" w-24">
        <img className=" w-full" src={`./media/img/companies/${item.img}`} alt="" />
      </div>
      <p className=" font-bold text-menu-item">{item.name}</p>
    </NavLink>
  ));

  return (
    <div className=" h-screen w-screen p-5 overflow-hidden">
      <div className=" w-full h-full grid grid-cols-12 gap-3 overflow-x-hidden overflow-y-scroll">
        {COMPANY}
      </div>
    </div>
  );
};

export default SelectCompany;
