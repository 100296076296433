type COLUMNS = {
  Header: string;
  accessor: string;
  type?: string;
  placeholder?: string;
  Cell?: ({ row }: { row: any }) => {};
};

type FORM = {
  commissioners_name: string | null;
  id_number: number | null;
  mandated_date:  Date | null;
  mandated_expiry_date:  Date | null;
  notes: string | null;
  trade_name: string | null;
  commercial_registration_no: number | null;
  release_date:  Date | null;
  expiration_date:  Date | null;
  director_registration: string | null;
  mcgov_file: File[] | null;
};

export const COM: COLUMNS[] = [
  {
    Header: "#",
    accessor: "id",
    Cell: ({ row }) => {
      const numRow = Number(row.id) + 1;
      return numRow;
    },
  },
  {
    Header: "اسم المفوض",
    accessor: "commissioners_name",
    type: "text",
    placeholder: "اسم المفوض",
  },
  {
    Header: "رقم الهوية",
    accessor: "id_number",
    type: "text",
    placeholder: "رقم الهوية",
  },
  {
    Header: "تاريخ التفويض",
    accessor: "mandated_date",
    type: "date",
  },
  {
    Header: "تاريخ انتهاء التفويض",
    accessor: "mandated_expiry_date",
    type: "date",
  },
  {
    Header: "الملاحظات",
    accessor: "notes",
    type: "text",
    placeholder: "ملاحظة",
  },
  {
    Header: "الاسم التجاري",
    accessor: "trade_name",
    type: "text",
    placeholder: "الاسم التجاري",
  },
  {
    Header: "رقم السجل التجاري",
    accessor: "commercial_registration_no",
    type: "text",
    placeholder: "رقم السجل التجاري",
  },
  {
    Header: "تاريخ الاصدار",
    accessor: "release_date",
    type: "date",
  },
  {
    Header: "تاريخ  الانتهاء ",
    accessor: "expiration_date",
    type: "date",
  },
  {
    Header: "مدير السجل التجاري",
    accessor: "director_registration",
    type: "text",
    placeholder: "مدير السجل التجاري",
  },
  {
    Header: "المرفقات",
    accessor: "mcgov_file",
    type: "file",
  },
];

export const FORM_COM: FORM = {
  commissioners_name: null,
  id_number: null,
  mandated_date: null,
  mandated_expiry_date: null,
  notes: null,
  trade_name: null,
  commercial_registration_no: null,
  release_date: null,
  expiration_date: null,
  director_registration: null,
  mcgov_file: null,
};
