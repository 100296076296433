/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { useTable, useSortBy, Column, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "./Pagination";
import EditForm from "../forms/EditForm";
import FileUpload from "../forms/FileUpload";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { setFilesDetails } from "../../../store/administrative/form";

type TableData = {
  [key: string]: any;
};

const Table: React.FC<any> = (props) => {
  const columns: Column<TableData>[] = useMemo(() => props.columns, []);
  const data: TableData[] = useMemo(() => props.data, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    canPreviousPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    prepareRow,
  }: any = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = state;
  const dispatch = useAppDispatch();
  const { filename, filesDetails } = useAppSelector(
    (state) => state.administrativeForm
  );

  return (
    <>
      <div className="bg-white py-2 px-4 rounded-tr-md rounded-tl-md overflow-hidden">
        <div className=" h-vh-minus-190 overflow-scroll">
          <table
            className="min-w-[400px] w-full text-table"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr
                  className=" border-b-2 border-[#dee2e6]"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column: TableData) => (
                    <th
                      className=" py-2 px-4 pb-3 text-center whitespace-nowrap"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className=" -mr-2 ml-1 inline-block">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              className=" text-[#71b6f9]"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSortUp}
                              className=" text-[#71b6f9]"
                            />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {props.children}
              {props.btnAdd ? props.children : ""}
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <>
                    {row.original.id === props.showFormEdit.id ? (
                      <EditForm
                        values={row}
                        data={props.itemData}
                        closeForm={props.closeFormEdit}
                        rules={props.rules}
                        fun={props.funEdit}
                        submit={props.submit}
                        list={props.list}
                        key={row.original.id}
                      />
                    ) : (
                      <tr
                        className="hover:bg-[#dee2e6] transition-all duration-100  border-b border-[#dee2e6]"
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell: any) => {
                          return (
                            <td
                              className=" text-center px-2 py-3 whitespace-nowrap"
                              {...cell.getCellProps()}
                            >
                              {cell.column.type === "file" ? (
                                <>
                                  <div
                                    className=" text-white cursor-pointer gap-1 bg-muted shadow-box-2 rounded-md py-1 px-3 whitespace-nowrap"
                                    onClick={() =>
                                      dispatch(
                                        setFilesDetails({
                                          data: row.values[filename],
                                          filed: "view",
                                          showModal: true,
                                        })
                                      )
                                    }
                                  >
                                    <p>مرفقات</p>
                                  </div>
                                  {filesDetails.showModal && <FileUpload />}
                                </>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        columns={props.columns}
        data={props.data}
        nextPage={nextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        pageOptions={pageOptions}
        gotoPage={gotoPage}
        pageCount={pageCount}
        setPageSize={setPageSize}
        pageSize={pageSize}
        pageIndex={pageIndex}
      />
    </>
  );
};

export default Table;
