import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faUsers,
  faSheetPlastic,
  faLock,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { setShowSidebarMobile } from "../../../store/global";

interface routes {
  id: number;
  path: string;
  end: boolean;
  icon: JSX.Element;
  text: string;
}

const LeftMenu: React.FC = () => {
  const dispatch = useAppDispatch();

  const itemsNav: routes[] = [
    {
      id: 0,
      path: "/dashboard",
      end: true,
      icon: <FontAwesomeIcon icon={faChartPie} className=" w-5" />,
      text: "لوحة التحكم",
    },
    {
      id: 1,
      path: "/dashboard/staff",
      end: false,
      icon: <FontAwesomeIcon icon={faUsers} className=" w-5" />,
      text: "الموظفين",
    },
    {
      id: 2,
      path: "/dashboard/administrative_affairs",
      end: false,
      icon: <FontAwesomeIcon icon={faSheetPlastic} className=" w-5" />,
      text: "الشؤون الأدارية",
    },
    {
      id: 3,
      path: "/dashboard/permission",
      end: false,
      icon: <FontAwesomeIcon icon={faLock} className=" w-5" />,
      text: "الصلاحيات",
    },
    {
      id: 4,
      path: "/dashboard/support",
      end: false,
      icon: <FontAwesomeIcon icon={faHeadset} />,
      text: "الدعم",
    },
  ];

  const item = itemsNav.map((item) => (
    <li key={item.id}>
      <NavLink
        to={item.path}
        end={item.end}
        style={({ isActive }) => (isActive ? { color: "#af9744" } : {})}
        className=" flex items-center gap-2 text-lg text-menu-item hover:text-[#af9744] transition-all duration-100"
        onClick={() => dispatch(setShowSidebarMobile())}
      >
        {item.icon}
        <p>{item.text}</p>
      </NavLink>
    </li>
  ));

  return <ul className=" flex flex-col gap-3">{item}</ul>;
};

export default LeftMenu;
