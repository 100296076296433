import { createSlice } from "@reduxjs/toolkit";

type form = {
  first_name: string;
  last_name: string;
  personal_photo: string;
  nationality: string;
  email: string;
  password: string;
  phone_number: string;
  roles_name: string[];
};

interface staffForm {
  btnAdd: boolean;
  sendForm: boolean;
  staff: any;
  isShowFormAddStaff: boolean;
  isShowFormEditStaff: { status: boolean; id: number | null };
  field: string;
}

const initialState: staffForm = {
  btnAdd: false,
  sendForm: false,
  staff: {
    first_name: null,
    last_name: null,
    personal_photo: null,
    nationality: null,
    email: null,
    password: "12345678",
    phone_number: null,
    roles_name: null,
  },
  isShowFormAddStaff: false,
  isShowFormEditStaff: { status: false, id: null },
  field: "",
};

const staffFormSlice = createSlice({
  name: "staffFormSlice",
  initialState,
  reducers: {
    setBtnAdd: (state) => {
      state.btnAdd = !state.btnAdd;
    },
    setOpenFormAddStaff: (state) => {
      state.field = "addMode";
      state.isShowFormAddStaff = true;
      state.staff.first_name = null;
      state.staff.last_name = null;
      state.staff.personal_photo = null;
      state.staff.nationality = null;
      state.staff.email = null;
      state.staff.password = "12345678";
      state.staff.phone_number = null;
      state.staff.roles_name = null;
    },
    setCloseFormAddStaff: (state) => {
      state.field = "";
      state.isShowFormAddStaff = false;
      state.staff.first_name = null;
      state.staff.last_name = null;
      state.staff.personal_photo = null;
      state.staff.nationality = null;
      state.staff.email = null;
      state.staff.password = "12345678";
      state.staff.phone_number = null;
      state.staff.roles_name = null;
    },
    setOpenFormEditStaff: (state: any, action) => {
      state.field = "editMode";
      state.isShowFormEditStaff.status = true;
      state.isShowFormEditStaff.id = action.payload.id;
      state.staff.id = action.payload.id;
      state.staff.first_name = action.payload.first_name;
      state.staff.last_name = action.payload.last_name;
      state.staff.personal_photo = null;
      state.staff.nationality = action.payload.nationality;
      state.staff.email = action.payload.email;
      state.staff.phone_number = action.payload.phone_number;
      state.staff.roles_name = action.payload.roles_name[0];
    },
    setCloseFormEditStaff: (state: any) => {
      state.field = "";
      state.isShowFormEditStaff.status = false;
      state.isShowFormEditStaff.id = null;
      state.staff.first_name = null;
      state.staff.last_name = null;
      state.staff.personal_photo = null;
      state.staff.nationality = null;
      state.staff.email = null;
      state.staff.phone_number = null;
      state.staff.roles_name = null;
    },
    setSendForm: (state) => {
      state.sendForm = true;
    },
    setRoles: (state, action) => {
      state.staff.roles_name = action.payload;
    },
    setForm: (state, action) => {
      const { name, value } = action.payload;
      state.staff[name] = value;
    },
  },
});

export default staffFormSlice.reducer;
export const {
  setBtnAdd,
  setSendForm,
  setRoles,
  setForm,
  setOpenFormAddStaff,
  setCloseFormAddStaff,
  setOpenFormEditStaff,
  setCloseFormEditStaff,
} = staffFormSlice.actions;
