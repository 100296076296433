import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useMsgInfo } from "../../toastify/notification";
import useDeleteItem from "../../toastify/deleteItem";
import { PERMISSION } from "../../schema/tables/permission/permission";
import PlanCompanies from "../../components/dashboard/layouts/PlanCompnies";
import Form from "../../components/dashboard/forms/Form";
import {
  setOpenFormAddPermission,
  setCloseFormAddPermission,
  setCloseFormEditPermission,
  setOpenFormEditPermission,
  setFormData,
} from "../../store/permission/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import {
  createItemPermission,
  getAllItemsPermission,
  deleteItemPermission,
  updateItemPermission,
} from "../../store/permission";

const Permission: React.FC = () => {
  const message = useMsgInfo;
  const deleteItem = useDeleteItem;
  const dispatch = useAppDispatch();
  const { rulesData, isLoading } = useAppSelector((state) => state.permission);
  const { roles, isShowFormAddPermission, isShowFormEditPermission, field } =
    useAppSelector((state) => state.permissionForm);

  const handlerDelete = (id: number) => {
    dispatch(deleteItemPermission(id));
  };

  useEffect(() => {
    dispatch(getAllItemsPermission(1));
  }, [dispatch]);

  const rules = [
    "user",
    "users-list",
    "update-user",
    "delete-user",
    "add-user",
    "permission",
    "permission-list",
    "update-permission",
    "delete-permission",
    "add-permission",
    "delete-madd",
    "update-madd",
    "add-madd",
    "madd",
    "madd-list",
    "delete-sociaSecurity",
    "update-sociaSecurity",
    "add-sociaSecurity",
    "sociaSecurity-list",
    "sociaSecurity",
    "qiwa",
    "qiwa-list",
    "delete-qiwa",
    "update-qiwa",
    "add-qiwa",
    "senaei",
    "senaei-list",
    "delete-senaei",
    "update-senaei",
    "add-senaei",
    "salamah",
    "salamah-list",
    "delete-salamah",
    "update-salamah",
    "add-salamah",
    "balady",
    "balady-list",
    "delete-balady",
    "update-balady",
    "add-balady",
    "zakat",
    "zakat-list",
    "delete-zakat",
    "update-zakat",
    "add-zakat",
    "muqeem",
    "muqeem-list",
    "delete-muqeem",
    "update-muqeem",
    "add-muqeem",
    "riyadh_chamber",
    "riyadh_chamber-list",
    "delete-riyadh_chamber",
    "update-riyadh_chamber",
    "add-riyadh_chamber",
    "etimad",
    "etimad-list",
    "delete-etimad",
    "update-etimad",
    "add-etimad",
    "modon",
    "modon-list",
    "delete-modon",
    "update-modon",
    "add-modon",
    "Sca",
    "Sca-list",
    "delete-Sca",
    "update-Sca",
    "add-Sca",
    "ejar",
    "ejar-list",
    "delete-ejar",
    "update-ejar",
    "add-ejar",
    "tamm",
    "tamm-list",
    "delete-tamm",
    "update-tamm",
    "add-tamm",
    "water",
    "water-list",
    "delete-water",
    "update-water",
    "add-water",
    "mcgov",
    "mcgov-list",
    "delete-mcgov",
    "update-mcgov",
    "add-mcgov",
    "commercial",
    "commercial-list",
    "delete-commercial",
    "update-commercial",
    "add-commercial",
    "najis",
    "najis-list",
    "delete-najis",
    "update-najis",
    "add-najis",
    "electricity",
    "electricity-list",
    "delete-electricity",
    "update-electricity",
    "add-electricity",
    "parking",
    "parking-list",
    "delete-parking",
    "update-parking",
    "add-parking",
  ];

  const submit = (indexItem?: Number) => {
    if (roles.name.length === 0) {
      message(`أدخل أسم الصلاحية`);
    } else if (roles.permission.length === 0) {
      message(`أدخل صلاحيات`);
    } else {
      if (field === "addMode") {
        dispatch(createItemPermission(roles)).then(() => {
          dispatch(setCloseFormAddPermission());
        });
      } else if (field === "editMode") {
        dispatch(updateItemPermission({ form: roles, indexItem })).then(() => {
          dispatch(setCloseFormEditPermission());
        });
      }
    }
  };

  const PERM: any = [
    ...PERMISSION,
    {
      Header: "إجراءات",
      Cell: ({ row }: { row: any }) => (
        <div className=" text-center whitespace-nowrap flex justify-center items-center gap-3">
          <FontAwesomeIcon
            icon={faPen}
            className="text-[#24c732] text-lg cursor-pointer"
            onClick={() => dispatch(setOpenFormEditPermission(row.original))}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            className="text-[#d9003f] text-lg cursor-pointer"
            onClick={() => deleteItem(() => handlerDelete(row.original.id))}
          />
        </div>
      ),
    },
  ];

  return (
    <PlanCompanies
      columns={PERM}
      data={rulesData}
      ShowFormAdd={isShowFormAddPermission}
      openFormAdd={setOpenFormAddPermission}
      loading={isLoading}
      btnBackPage={false}
      showFormEdit={isShowFormEditPermission}
      closeFormEdit={setCloseFormEditPermission}
      funEdit={setFormData}
      itemData={roles}
      submit={submit}
      rules={rules}
    >
      <Form
        inputs={PERMISSION}
        closeForm={setCloseFormAddPermission}
        rules={rules}
        fun={setFormData}
        submit={submit}
        values={roles}
      />
    </PlanCompanies>
  );
};

export default Permission;
